<template>
  <div class="space-y-1">
    <p class="text-l">{{ equipment.name }}</p>
    <div class="grid gap-x-2 items-center grid-cols-[max-content,auto]">
      <equipment-summary-item label="Year">{{ equipment.year }}</equipment-summary-item>
      <equipment-summary-item label="Make">{{ equipment.make }}</equipment-summary-item>
      <equipment-summary-item label="Model">{{ equipment.model }}</equipment-summary-item>
      <equipment-summary-item label="Cost">{{ equipment.operationCost }}</equipment-summary-item>
      <equipment-summary-item label="Type">{{ equipment.jobType?.name }}</equipment-summary-item>
      <equipment-summary-item label="Level">{{ equipment.jobType?.priorityLevel }}</equipment-summary-item>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EquipmentSummaryItem from "@/views/admin/EquipmentSummaryItem.vue";

export default defineComponent({
  name: "EquipmentSummary",
  components: {EquipmentSummaryItem},
  props: ["equipment"],
});
</script>
