
import { defineComponent } from "vue";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";

export default defineComponent({
  name: "DhDuration",
  props: {
    value: Number,
  },
  setup() {
    const formatDistanceLocale = { xSeconds: "{{count}}s", xMinutes: "{{count}}m", xHours: "{{count}}h" };
    type DistanceLocaleType = typeof formatDistanceLocale;
    const locale = { formatDistance: (token: keyof DistanceLocaleType, count: string) => formatDistanceLocale[token].replace("{{count}}", count) }

    return {
      formatDuration,
      intervalToDuration,
      locale,
    };
  },
});
