<template>
  <dh-header>
    Manage Beacons
    <template #buttons>
      <dh-button icon="export" variant="none" class="text-gray-500" @click="exportToCsv">Export to CSV</dh-button>
      <dh-button icon="add" variant="none" class="text-yellow-300" route="beacons.create">Add Beacon</dh-button>
    </template>
  </dh-header>
  <dh-table :rows="rows" :loading="loading" id-field="beaconID">
    <dh-table-column label="Make" :value="beacon => beacon.make"></dh-table-column>
    <dh-table-column label="Model" :value="beacon => beacon.model"></dh-table-column>
    <dh-table-column label="Equipment" :value="beacon => beacon.equipment?.name"></dh-table-column>
    <dh-table-column label="Activation Date" :value="beacon => beacon.equipment?.createdAt"></dh-table-column>
    <dh-table-column label="Battery Life" :value="() => 'Battery Life'"></dh-table-column>
    <dh-table-column label="Status" :value="() => 'Status'"></dh-table-column>
    <template v-slot:edit="{ row }">
      <div class="space-y-4">
        <div class="text-right space-x-4">
          <dh-button icon="pencil" variant="info" route="beacons.edit" :params="{ id: row.beaconID }">Edit</dh-button>
          <dh-button icon="trash" variant="danger" @click="handleDelete" :data-id="row.beaconID">Delete</dh-button>
        </div>
      </div>
    </template>
  </dh-table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import {beacons, locations} from "@/api";
import {useRemoteList} from "@/hooks/useRemoteList";
import {useHandleDelete} from "@/hooks/useHandleDelete";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import {useExportToCsv} from "@/hooks/useExportToCsv";

export default defineComponent({
  name: "Beacon",
  components: {
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
  },
  setup() {
    const [rows, pagination, loading] = useRemoteList(beacons.list, { idField: "beaconID" });

    const handleDelete = useRemoteMutation({
      method: "delete",
      idField: "beaconID",
      action: async event => {
        const id: string = (event.target as HTMLButtonElement).dataset.id as string;
        return beacons.delete(id);
      },
    });

    const exportToCsv = useExportToCsv({
      filename: "beacons.csv",
      action: () =>
        rows.value.map(row => ({
          Make: row.make,
          Model: row.model,
          Equipment: row.equipment?.name,
          "Activation Date": row.equipment?.createdAt,
          "Battery Life": "",
          Status: "",
        })),
    });

    return {
      rows,
      loading,
      exportToCsv,
      handleDelete,
    };
  },
});
</script>
