
import {defineComponent, inject, reactive, ref} from "vue";
import { useRouter } from "vue-router";
import DhHeader from "@/components/DhHeader.vue";
import DhButton from "@/components/DhButton.vue";
import {useRemoteData} from "@/hooks/useRemoteData";
import {useRemoteList} from "@/hooks/useRemoteList";
import {Beacon, beacons, Tenant, tenants as tenantApi} from "@/api";
import DhInput from "@/components/DhInput.vue";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import {useExtendedMouseEvent} from "@/hooks/useExtendedMouseEvent";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import eventemitter from "eventemitter3";

export default defineComponent({
  name: "Profile",
  components: {
    DhInput,
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
  },
  setup() {
    const router = useRouter();
    const saving = ref(false);
    const [tenants, pagination, loading] = useRemoteList(tenantApi.list);
    const newTenant = reactive({name: '', phoneNumber: ''});
    const emitter = inject("eventemitter") as eventemitter;

    const saveTenant = async (event: MouseEvent, id: string) => {
      event.preventDefault();
      const tenant = tenants.value.find(t => t.id === id);
      if (!tenant) {
        return;
      }

      const body: Partial<Tenant> = {
        id: tenant.id,
        name: tenant.name,
        phoneNumber: tenant.phoneNumber,
        contactEmail: tenant.contactEmail,
      };
      saving.value = true;
      await tenantApi.upsert(body);
      saving.value = false;
    };

    const logout = () => {
      localStorage.removeItem("token");
      router.push({ name: "login" });
    };

    const saveNewTenant = (event: MouseEvent) => {
      event.preventDefault();
      tenantApi.upsert(newTenant);
    };

    const isSwitching = ref(false);
    const handleSwitch = async (tenantID: string, event: MouseEvent) => {
      event.preventDefault();
      isSwitching.value = true;
      await tenantApi.switch(tenantID);
      emitter.emit("tenant:update");
      isSwitching.value = false;
    };

    return {
      tenants,
      saving,
      newTenant,
      saveTenant,
      saveNewTenant,
      logout,
      loading,
      handleSwitch,
      isSwitching,
    };
  },
});
