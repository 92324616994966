
import { defineComponent } from "vue";

export default defineComponent({
  name: "DhHeader",
  setup(props, { slots }) {
    const hasSubHead = slots.subhead && slots.subhead().length;
    return { hasSubHead };
  },
});
