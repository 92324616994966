
import {defineComponent, ref} from "vue";
import {locations} from "@/api";
import DhSlideOverForm, {ExtendedMouseEvent} from "@/components/DhSlideOverForm.vue";
import DhLabel from "@/components/DhLabel.vue";

export default defineComponent({
  name: "Profile",
  components: {
    DhLabel,
    DhSlideOverForm,
  },
  setup() {
    const fileInput = ref<HTMLInputElement>();

    const handleSubmit = (event: ExtendedMouseEvent) => {
      if (!fileInput.value) {
        throw Error("A valid zip file is required");
      }

      if (!fileInput.value?.files?.length) {
        throw Error("You must upload at least one zip file");
      }

      const body = new FormData();
      body.append("geodata_bundle", fileInput.value?.files[0]);

      event.waitUntil(locations.geofences.import(body));
    };

    return {
      fileInput,
      handleSubmit,
    };
  },
});
