
import {computed, defineComponent, reactive, watch} from "vue";
import DhSlideOver from "@/components/DhSlideOver.vue";
import { useRemoteData } from "@/hooks/useRemoteData";
import {tickets, devices, Device} from "@/api";
import DhMap from "@/components/DhMap.vue";
import DhMapPin from "@/components/DhMapPin.vue";
import EquipmentSummaryItem from "@/views/admin/EquipmentSummaryItem.vue";
import DhDateTime from "@/components/DhDateTime.vue";
import DhDuration from "@/components/DhDuration.vue";
import AppleIcon from "@/assets/apple.svg";
import AndroidIcon from "@/assets/android.svg";
import LockIcon from "@/assets/lock.svg";

export default defineComponent({
  name: "TimeCardsDetail",
  components: {
    DhDuration,
    DhDateTime,
    EquipmentSummaryItem,
    DhMapPin,
    DhMap,
    DhSlideOver,
    AppleIcon,
    AndroidIcon,
    LockIcon,
  },
  setup() {
    const data = useRemoteData(tickets.batch.get, {
      redirect: "timecards",
    });

    const device = reactive<Device | object>({});
    watch(
      () => data.deviceID,
      deviceID =>
        devices.get(deviceID).then(res => {
          Object.assign(device, res.data);
        }),
    );

    return {
      data,
      device,
    };
  },
});
