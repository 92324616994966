<template>
  <dh-optional-element :enabled="contained" :class="containerClassName">
    <dh-label :id="id" :required="required" :class="labelClassName">{{ label }}</dh-label>
    <select
      :class="'border-2 border-solid border-gray-400 rounded-lg p-2 w-full ' + selectClassName"
      :required="required"
      :value="computedValue"
      @input="handleInput"
      :placeholder="'Select ' + label"
    >
      <option value=""></option>
      <option v-for="option in options"
              :key="option.value"
              :value="option.value"
              :selected="option.value === computedValue"
              :disabled="(option.disabled && option.value !== computedValue) || false"
      >
        {{ option.label }}
      </option>
    </select>
  </dh-optional-element>
</template>

<script lang="ts">
import { defineComponent, toRef, ref, watch } from "vue";
import DhLabel from "./DhLabel.vue";
import DhOptionalElement from "@/components/DhOptionalElement.vue";

let trackedInputs = 0;
export default defineComponent({
  name: "DhSelect",
  props: {
    id: String,
    name: String,
    label: String,
    options: Array,
    modelValue: [String, Number, Object],
    modelKey: String,
    required: Boolean,
    containerClassName: String,
    contained: {
      type: Boolean,
      default: true,
    },
    labelClassName: {
      type: String,
      default: "",
    },
    selectClassName: {
      type: String,
      default: "",
    },
  },
  components: {
    DhOptionalElement,
    DhLabel,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const passedName = props.name || `select-${trackedInputs++}`;
    const name = passedName;
    const id = props.id || passedName;
    const label = props.label || passedName;
    const modelKey = props.modelKey || null;

    const computedValue = ref(null) as any;
    const boundValue = toRef(props, "modelValue") as any;
    watch(boundValue, (v: any) => {
      if (modelKey) {
        computedValue.value = (v && v[modelKey]) || null;
      } else {
        computedValue.value = v;
      }
    });

    const handleInput = (event: InputEvent) => {
      const newValue = (event.target as HTMLSelectElement).value;
      let result;
      if (modelKey) {
        const newObject = (props.modelValue || {}) as any;
        newObject[modelKey] = newValue;
        result = newObject;
      } else {
        result = newValue;
      }
      emit("update:modelValue", result);
    };

    return {
      computedValue,
      boundValue,
      handleInput,
      computed: {
        name,
        id,
        label,
      },
    };
  },
});
</script>
