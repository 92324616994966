<template>
  <dh-optional-element :enabled="contained">
    <dh-label :id="id" :required="required">{{ label }}</dh-label>
    <select
      v-if="input.type === 'select'"
      :id="input.id"
      :name="input.name"
      :required="required"
      class="border-2 border-solid border-gray-400 rounded-lg p-2 w-full"
    >
      <option v-for="(name, value) in options" :key="value" :value="value">{{ name }}</option>
    </select>
    <input
      v-if="input.type !== 'select'"
      :id="input.id"
      :name="input.name"
      :class="'border-2 border-solid border-gray-400 rounded-lg p-2 w-full ' + inputClassName"
      :placeholder="input.placeholder"
      :type="input.type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :required="required"
      ref="inputRef"
    />
  </dh-optional-element>
</template>

<script lang="ts">
import { defineComponent, toRefs, ref, watch, computed } from "vue";
import DhLabel from "./DhLabel.vue";
import DhOptionalElement from "./DhOptionalElement.vue";

let trackedInputs = 0;
export default defineComponent({
  name: "DhInput",
  props: {
    id: String,
    name: String,
    placeholder: String,
    label: String,
    type: String,
    options: Object,
    modelValue: [String, Number],
    required: Boolean,
    contained: {
      type: Boolean,
      default: true,
    },
    inputClassName: {
      type: String,
      default: "",
    }
  },
  components: {
    DhLabel,
    DhOptionalElement,
  },
  emits: ["update:modelValue"],
  setup(props) {
    const passedName = props.name || `input-${trackedInputs++}`;
    const name = passedName;
    const id = props.id || passedName;
    const label = props.label || passedName;
    const placeholder = props.placeholder || label;
    const type = props.type || "text";
    const inputRef = ref<HTMLInputElement>();

    return {
      inputRef,
      input: {
        name,
        id,
        label,
        placeholder,
        type,
      },
    };
  },
});
</script>
