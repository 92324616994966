import { ExtendedMouseEvent } from "@/components/DhSlideOverForm.vue";
import { inject } from "vue";
import eventemitter from "eventemitter3";
import { useRouter } from "vue-router";

interface UseHandleSubmitParams {
  redirect?: string;
  method?: "update" | "delete";
  idField?: string;
  action: (event: ExtendedMouseEvent) => Promise<any> | undefined;
}

export const useRemoteMutation = (params: UseHandleSubmitParams) => {
  const emitter = inject("eventemitter") as eventemitter;
  const router = useRouter();
  const { idField = "id" } = params || {};

  return (event: ExtendedMouseEvent) => {
    event.preventDefault();

    const action = Promise.resolve(params.action(event))
      .then(response => {
        if (response && response.data) {
          const data = response.data;

          if (data[idField]) {
            emitter.emit(`model:${params.method || "update"}`, data);
            emitter.emit(`model:${params.method || "update"}:${data[idField]}`, data);
          }
        }
        if (params.redirect) {
          router.push({ name: params.redirect });
        }
      })
      .catch(err => {
        alert(err.message);
      });

    if (event.waitUntil) {
      event.waitUntil(action);
    } else {
      Promise.all([action]);
    }
  };
};
