<template>
  <dh-header>
    Help
  </dh-header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";

export default defineComponent({
  name: "Help",
  components: {
    DhHeader,
  },
  setup() {
    return {};
  },
});
</script>
