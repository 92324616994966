<template>
  <div class="w-full overflow-auto position-sticky bottom-0">
    <ul class="flex space-x-2">
      <li v-for="page of pageLinks" :key="page">
        <router-link
          :to="{ name: to, query: { page } }"
          @click="scrollToTop"
          :class="{
            'inline-block': true,
            'border-2': true,
            'bg-gray-200': activePage !== page,
            'bg-yellow-100': activePage === page,
            'border-transparent': activePage !== page,
            'border-yellow-300': activePage === page,
            'px-4': true,
            'py-2': true,
            rounded: true,
            'text-xs': true,
            'hover:bg-gray-300': activePage !== page,
            'cursor-default': activePage === page,
          }"
        >
          {{ page }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch, computed, toRef} from "vue";
import { useRoute } from "vue-router";

const clamp = (min: number, value: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

export default defineComponent({
  name: "DhPagination",
  props: {
    pages: { type: Number, required: true },
    to: String,
  },
  components: {},
  setup(props) {
    const route = useRoute();

    const activePage = ref(route.query.page ? parseInt(route.query.page as string) : 1);
    watch(
      () => route.query.page,
      page => (activePage.value = page ? parseInt(page as string) : 1),
    );

    const pagesRef = toRef(props, "pages");
    const pageLinks = computed(() => {
      const allPageLinks: number[] = [];

      if (pagesRef.value === 0) {
        return allPageLinks;
      }

      allPageLinks.push(1);

      if (pagesRef.value > 2) {
        const start = clamp(2, activePage.value - 5, pagesRef.value - 1);
        const end = clamp(2, activePage.value + 5, pagesRef.value - 1);
        for (let i = start; i <= end; i++) {
          allPageLinks.push(i);
        }
      }

      if (pagesRef.value > 1) {
        allPageLinks.push(pagesRef.value);
      }

      return allPageLinks;
    });

    const scrollToTop = () => {
      window.scrollTo(0,0);
    };

    return {
      activePage,
      pageLinks,
      scrollToTop,
    };
  },
});
</script>
