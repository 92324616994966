
import { defineComponent, inject, ref, watch } from "vue";
import DhInput from "../../components/DhInput.vue";
import DhSelect from "../../components/DhSelect.vue";
import DhSlideOverForm from "../../components/DhSlideOverForm.vue";
import { equipment, jobTypes, Equipment, beacons, EquipmentPut, EquipmentQuestion } from "@/api";
import { useRemoteData } from "@/hooks/useRemoteData";
import { useAsyncRef } from "@/hooks/useAysncComputed";
import { useRemoteMutation } from "@/hooks/useRemoteMutation";
import eventemitter from "eventemitter3";

export default defineComponent({
  name: "EquipmentForm",
  components: {
    DhSlideOverForm,
    DhInput,
    DhSelect,
  },
  setup() {
    const data = useRemoteData(equipment.get, {
      redirect: "equipment",
    });
    const jobTypeOptions = useAsyncRef(async () => (await jobTypes.list()).data.map(j => ({ value: j.id, label: j.name })));
    const beaconOptions = useAsyncRef(async () => {
      const beaconData = (await beacons.list()).data;
      return beaconData.map(b => ({
        value: b.beaconID,
        label: `${b.make} ${b.model} (${b.uniqueID})`,
        disabled: true,
      }));
    });
    const questions = useAsyncRef(async () => (await equipment.questions.list()).data);
    const selectedQuestionIds = ref<Array<string>>([]);
    watch(
      () => data.equipmentQuestions,
      (questions: EquipmentQuestion[]) => {
        selectedQuestionIds.value = questions.map(question => question.id);
      },
    );

    const handleSubmit = useRemoteMutation({
      redirect: "equipment",
      action: () => {
        const body: Partial<EquipmentPut> = {
          id: data.id,
          name: data.name,
          description: data.description,
          year: data.year,
          make: data.make,
          model: data.model,
          operationCost: data.operationCost,
          jobTypeID: data.jobType?.id,
          becaonReferenceID: data.beacon?.beaconID,
          questionIDs: selectedQuestionIds.value,
          deleteQuestions: true,
        };
        return equipment.upsert(body);
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "equipment",
      method: "delete",
      action: () => equipment.delete(data.id).then(res => ({ id: res.data.equipmentID })),
    });

    return {
      data,
      beaconOptions,
      jobTypeOptions,
      questions,
      selectedQuestionIds,
      handleSubmit,
      handleDelete,
    };
  },
});
