
import { defineComponent, toRef } from "vue";
import IconExport from "../assets/export.svg";
import IconAdd from "../assets/add.svg";
import IconPencil from "../assets/pencil.svg";
import IconTrash from "../assets/trash.svg";

export default defineComponent({
  name: "DhButton",
  props: {
    icon: String,
    to: Object,
    route: String,
    params: Object,
    href: String,
    variant: String,
    loading: Boolean,
  },
  components: {
    IconExport,
    IconAdd,
    IconPencil,
    IconTrash,
  },
  setup(props) {
    const boundProps: { to?: { name?: string; params?: object }, href?: string } = {};

    if (props.route) {
      boundProps.to = { name: props.route };
    }

    const to = toRef(props, "to");
    if (to.value) {
      boundProps.to = to.value as any;
    }

    const params = toRef(props, "params");
    if (params.value) {
      if (!boundProps.to) {
        throw Error("Trying to set route params without setting the route first");
      }
      boundProps.to.params = params.value;
    }

    const href = toRef(props, "href");
    if (href.value) {
      boundProps.href = href.value;
    }

    let classes = "py-2 px-6 inline-block uppercase";
    if (props.variant === "danger") {
      classes += " bg-red-200 text-red-800 rounded-full";
    } else if (props.variant === "none") {
      classes += "rounded-full";
    } else if (props.variant === "action") {
      classes += "text-center bg-yellow-300 rounded-lg w-full text-sm";
    } else {
      classes += " bg-gray-200 text-gray-800 rounded-full";
    }

    return {
      boundProps,
      classes,
    };
  },
});
