
import { defineComponent } from "vue";
import dateFnsParseISO from "date-fns/parseISO";
import dateFnsFormat from "date-fns/format";

export default defineComponent({
  name: "DhDateTime",
  props: {
    value: { type: undefined, required: true },
    format: String,
  },
  setup() {
    return {
      dateFnsParseISO,
      dateFnsFormat,
    };
  },
});
