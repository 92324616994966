
import { defineComponent } from "vue";
import DhLabel from "./DhLabel.vue";

let trackedInputs = 0;
export default defineComponent({
  name: "DhTextarea",
  props: {
    id: String,
    name: String,
    placeholder: String,
    label: String,
    modelValue: [String, Number],
    required: Boolean,
    caption: String,
    rows: Number,
  },
  components: {
    DhLabel,
  },
  emits: ["update:modelValue"],
  setup(props) {
    const passedName = props.name || `input-${trackedInputs++}`;
    const name = passedName;
    const id = props.id || passedName;
    const label = props.label || passedName;
    const placeholder = props.placeholder || label;

    return {
      input: {
        name,
        id,
        label,
        placeholder,
      },
    };
  },
});
