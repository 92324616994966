import { Ref } from "vue";
const perPage = 50;

export interface MakeRequestProps {
  path: string;
  // eslint-disable-next-line
  body?: any;
  // eslint-disable-next-line
  query?: any;
  rawBody?: FormData;
  method?: "post" | "get" | "put" | "delete";
  headers?: Record<string, any>;
  json?: boolean;
}

interface FetchParams {
  method: string;
  // eslint-disable-next-line
  headers: any;
  // eslint-disable-next-line
  body?: any;
}

export interface Pagination {
  currentPage: number;
  items: number;
  pages: number;
  perPage: number;
}

export type ApiResponse<T> = {
  status: number;
  responseTime: string;
  data: T;
  pagination?: Pagination;
};

type HttpError = Error & {
  statusCode: number;
};

const makeRequest = async <T>(props: MakeRequestProps) => {
  let { path } = props;
  const { body = {}, query = {}, rawBody, method = "get", headers = {}, json = true } = props;

  if (headers["Content-Type"] === undefined) {
    headers["Content-Type"] = "application/json";
  }
  if (headers["Content-Type"] === false) {
    delete headers["Content-Type"];
  }

  const params: FetchParams = {
    method,
    headers: {
      ...headers,
    },
  };

  const queryEntries = Object.entries(query);
  if (queryEntries.length > 0) {
    const queryString = queryEntries.filter(e => e[1] !== undefined).map(e => `${e[0]}=${e[1]}`).join('&');
    if (queryString.length > 0) {
      path = path + "?" + queryString;
    }
  }

  if (localStorage.token) {
    params.headers.Authorization = localStorage.token;
  }

  if (rawBody) {
    params.body = rawBody;
  } else if (Object.entries(body).length > 0) {
    // eslint-disable-next-line
    const bodyEntries = Object.entries(body).map(([key, value]: [string, any]) => {
      if (value?.__v_isRef) {
        value = value.value;
      }
      return [key, value];
    });
    params.body = JSON.stringify(Object.fromEntries(bodyEntries));
  }

  const apiHost = window.location.host === "localhost:8080" ? "http://localhost:8080" : "https://api.dataharvest.us";
  const response: ApiResponse<T> = await fetch(`${apiHost}/api/${path.replace(/^\//, "")}`, params).then(response => {
    if (!response.headers.get('content-type')?.includes('json')) {
      throw new Error(response.status + " " + response.statusText + "\r\n" + 'An unknown error occurred.');
    }
    return json ? response.json() : response.text();
  });
  if (response.status === 401) {
    let message = (response.data as any).error;
    if (!message) {
      message = (response.data as any).message;
    }
    const error = new Error(message) as HttpError;
    error.statusCode = 401;
    throw error;
  }
  if (response.status && response.status !== 200) {
    let message = (response.data as any).error;
    if (!message) {
      message = (response.data as any).message;
    }
    throw new Error(message);
  }

  return response;
};

interface AuthTokenProps {
  username: Ref<string>;
  password: Ref<string>;
}

interface AuthToken {
  token: string;
}

export const auth = {
  token: (props: AuthTokenProps) => {
    const { username, password } = props;
    return makeRequest<AuthToken>({
      method: "post",
      path: "/auth/token",
      body: {
        username,
        password,
      },
    });
  },
};

export interface LocationGeofence {
  id: string;
  createdAt: string;
  name: string;
  description: string;
  entranceDescription: string;
  enabled: boolean;
  coordinateList: number[][];
  area: number;
}

export interface LocationGeofenceDelete {
  geofenceID: string;
}

export const locations = {
  geofences: {
    list(page = 1, overridePerPage: number | null = null) {
      return makeRequest<LocationGeofence[]>({
        method: "get",
        path: `/locations/geofences?perPage=${overridePerPage ? overridePerPage : perPage}&page=${page}`,
      });
    },
    get(id: string) {
      return makeRequest<LocationGeofence>({
        method: "get",
        path: `/locations/geofences/${id}`,
      });
    },
    post(body: Partial<LocationGeofence>) {
      return makeRequest<LocationGeofence>({
        method: "post",
        path: "/locations/geofences",
        body,
      });
    },
    upsert(params: Partial<LocationGeofence>) {
      const { id, ...body } = params;
      return makeRequest<LocationGeofence>({
        method: id ? "put" : "post",
        path: id ? `locations/geofences/${id}` : "locations/geofences",
        body,
      });
    },
    import(rawBody: FormData) {
      return makeRequest<any>({
        method: "post",
        path: "/locations/import",
        headers: {
          "Content-Type": false,
        },
        rawBody,
      });
    },
    delete(id: string) {
      return makeRequest<LocationGeofenceDelete>({
        method: "delete",
        path: `/locations/geofences/${id}`,
      });
    },
  },
};

export interface JobType {
  id: string;
  name?: string;
  priorityLevel?: number;
}

export interface Equipment {
  id: string;
  name: string;
  description: string;
  make: string;
  model: string;
  operationCost: number;
  beacon: Beacon;
  year: number;
  vin: string;
  createdAt: string;
  jobType?: JobType;
  jobTypeID: string;
  becaonReferenceID: string;
  equipmentQuestions: EquipmentQuestion[];
}

export interface EquipmentPut extends Equipment {
  questionIDs: Array<string>;
  deleteQuestions: boolean;
}

export interface EquipmentDelete {
  equipmentID: string;
}

export interface EquipmentQuestionChoices {
  value: string;
}

export interface EquipmentQuestion {
  id: string;
  type: string;
  subtype: string;
  label: string;
  defaultValue: string;
  placeholder: string;
  required: boolean;
  choices?: EquipmentQuestionChoices;
}

export const equipment = {
  list: () => {
    return makeRequest<Equipment[]>({
      method: "get",
      path: "/equipment",
    });
  },
  get: (id: string) => {
    return makeRequest<Equipment>({
      method: "get",
      path: `/equipment/${id}`,
    });
  },
  upsert: (params: Partial<EquipmentPut>) => {
    const { id, ...body } = params;
    return makeRequest<Equipment>({
      method: id ? "put" : "post",
      path: id ? `equipment/${id}` : "equipment",
      body,
    });
  },
  delete(id: string) {
    return makeRequest<EquipmentDelete>({
      method: "delete",
      path: `/equipment/${id}`,
    });
  },
  questions: {
    list: () => {
      return makeRequest<EquipmentQuestion[]>({
        method: "get",
        path: `/equipment/questions/list`,
      });
    },
  },
};

type Override<T1, T2> = Omit<T1, keyof T2> & T2;
type ProductBody = Override<
  Product,
  {
    purchaseUnit: string;
    distributedUnitNumerator: string;
    distributedUnitDenominator: string;
    conversionUnitLeft: string;
    conversionUnitRight: string;
  }
>;

export interface Product {
  id: string;
  name: string;
  description: string;
  type: string;
  category: string;
  purchaseUnitCost: number;
  purchaseUnit: Unit;
  distributedUnitNumerator: Unit;
  distributedUnitDenominator: Unit;
  conversionUnitLeft: Unit;
  conversionUnitRight: Unit;
  createdBy: User;
  lastModified: string;
}

export interface ProductDelete {
  productID: string;
}

export const products = {
  list: () => {
    return makeRequest<Product[]>({
      method: "get",
      path: "/products",
    });
  },
  get: (id: string) => {
    return makeRequest<Product>({
      method: "get",
      path: `/products/${id}`,
    });
  },
  upsert: (params: Partial<ProductBody>) => {
    const { id, ...body } = params;
    return makeRequest<Product>({
      method: id ? "put" : "post",
      path: id ? `products/${id}` : "products",
      body,
    });
  },
  delete: (id: string) => {
    return makeRequest<ProductDelete>({
      method: "delete",
      path: `/products/${id}`,
    });
  },
};

export interface Unit {
  identifier: string;
  abbreviation: string;
  name: string;
  plural: string;
  symbol: string;
}

export type UnitGrouping = Record<string, Unit[]>;

export const units = {
  list: () => {
    return makeRequest<UnitGrouping>({
      method: "get",
      path: "/utility/units",
    });
  },
};

export interface JobTypeDelete {
  jobTypeID: string;
}

export const jobTypes = {
  list: () => {
    return makeRequest<JobType[]>({
      method: "get",
      path: "/equipment/jobTypes",
    });
  },
  get: (id: string) => {
    return makeRequest<JobType>({
      method: "get",
      path: `/equipment/jobTypes/${id}`,
    });
  },
  upsert: (params: Partial<JobType>) => {
    const { id, ...body } = params;
    return makeRequest<JobType>({
      method: id ? "put" : "post",
      path: id ? `/equipment/jobTypes/${id}` : "/equipment/jobTypes",
      body,
    });
  },
  delete: (id: string) => {
    return makeRequest<JobTypeDelete>({
      method: "delete",
      path: `/equipment/jobTypes/${id}`,
    });
  },
};

interface TicketImage {
  id: string;
  title: string;
  url: string;
}

export interface Ticket {
  id: string | null;
  ticketNumber: string;
  timeStart: string;
  timeEnd: string;
  startingPoint: [number, number];
  endingPoint: [number, number];
  beacons: string[];
  location: LocationGeofence;
  locationID: string;
  ownerID: string;
  availableImages: TicketImage[];
  questions: EquipmentQuestion[];
  questionAnswers: EquipmentQuestionAnswer[];
  equipmentID: string;
  equipment: Equipment;
  secondaryEquipment: Equipment[];
  createdBy: User;
  lastModified: string;
  reasonForClose: string;
  deviceID: string;
}

export interface EquipmentQuestionAnswer {
  answer: string;
  choices?: Array<string>;
  id: string;
  equipmentID: string;
  questionID: string;
}

export interface TicketDelete {
  ticketID: string;
}

export interface TicketBatch {
  postDate: string;
  startLocation: number[][];
  postLocation: number[][];
  timeClockedIn: string;
  totalTime: number;
  createdAt: string;
  createdBy: User;
  lastModified: string;
  ticketList: string[];
  deviceID: string;
  batchID: string;
}

export const tickets = {
  list(page = 1, status: "open" | "closed" | undefined) {
    return makeRequest<Ticket[]>({
      method: "get",
      path: `/tickets?perPage=${perPage}&page=${page}${status ? `&status=${status}` : ""}`,
    });
  },
  get(id: string) {
    return makeRequest<Ticket>({
      method: "get",
      path: `/tickets/${id}`,
    });
  },
  upsert(params: Partial<Ticket>) {
    const { id, ...body } = params;
    return makeRequest<Ticket>({
      method: id ? "put" : "post",
      path: id ? `tickets/${id}` : "tickets",
      body,
    });
  },
  delete(id: string) {
    return makeRequest<TicketDelete>({
      method: "delete",
      path: `/tickets/${id}`,
    });
  },
  batch: {
    list(page = 1, { userID, postDate }: { userID: string | undefined, postDate: string | undefined }) {
      return makeRequest<TicketBatch[]>({
        method: "get",
        path: `/tickets/batch?page=${page}&perPage=${perPage}${userID ? `&userID=${userID}` : ""}${postDate ? `&postDate=${postDate}` : ""}`,
      });
    },
    get(id: string) {
      return makeRequest<TicketBatch>({
        method: "get",
        path: `/tickets/batch/${id}`,
      });
    },
  }
};

export const individuals = {
  list() {
    return makeRequest<Individual[]>({
      method: "get",
      path: "/individuals",
    });
  },
  get(id: string) {
    return makeRequest<Individual>({
      method: "get",
      path: `/individuals/${id}`,
    });
  },
  upsert(params: Partial<Individual>) {
    const { id, ...body } = params;
    return makeRequest<Individual>({
      method: id ? "put" : "post",
      path: id ? `individuals/${id}` : "individuals",
      body,
    });
  },
  delete(id: string) {
    return makeRequest<Individual>({
      method: "delete",
      path: `/individuals/${id}`,
    });
  },
};

export interface EmployeeData {
  employeeNumber: number;
  rate: number;
  type: "Part Time" | "Full Time" | "Contractor";
}

export interface User {
  id: string;
  status: string;
  individual: Individual;
  individualID: string;
  username: string;
  password: string;
  employeeData: EmployeeData;
  type: string;
  roleName: string;
}

export interface UserDelete {
  userID: string;
}

export const users = {
  me() {
    return makeRequest<User>({
      method: "get",
      path: "/users/me",
    });
  },
  logins() {
    return makeRequest<User>({
      method: "get",
      path: "/users/me/logins",
    });
  },
  list() {
    return makeRequest<User[]>({
      method: "get",
      path: "/users",
    });
  },
  get(id: string) {
    return makeRequest<User>({
      method: "get",
      path: `/users/${id}`,
    });
  },
  upsert(params: Partial<User>) {
    const { id, ...body } = params;
    return makeRequest<User>({
      method: id ? "put" : "post",
      path: id ? `users/${id}` : "users",
      body,
    });
  },
  delete(id: string) {
    return makeRequest<UserDelete>({
      method: "delete",
      path: `/users/${id}`,
    });
  },
  getRoles() {
    return makeRequest<string[]>({
      method: "get",
      path: "/users/roles",
    });
  },
};

export interface Individual {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  type: string;
}

export const reports = {
  pay(from: string, to: string, timezone: string|number) {
    return makeRequest<string>({
      method: "get",
      path: `/report/payperiod/${from}/${to}`,
      query: {
        timezone,
      },
      json: false,
    });
  },
};

interface DeviceOwner {
  id: string;
  username: string;
  createdAt: string;
  individual: Individual;
}

export interface Device {
  deviceID: string;
  make: string;
  model: string;
  owner: DeviceOwner;
}

export interface DeviceHistory {
  device: Device;
  collectedDate: string;
  latitude: number;
  longitude: number;
  altitude: number;
  batterylevel: number;
  speed: number;
  internetConnectionType: string;
}

export const devices = {
  list(page = 1, userId = "") {
    let userIdParam = "";
    if (userId !== "") {
      userIdParam = `&userID=${userId}`;
    }
    return makeRequest<Device[]>({
      method: "get",
      path: `/devices?perPage=50&page=${page}${userIdParam}`,
    });
  },
  get(id: string) {
    return makeRequest<Device>({
      method: "get",
      path: `/devices/${id}`,
    });
  },
  history: {
    get(deviceID: string, startDate?: number, endDate?: number) {
      const query = [];
      query.push(startDate ? `startDate=${startDate}` : "");
      query.push(endDate ? `endDate=${endDate}` : "");
      return makeRequest<DeviceHistory[]>({
        method: "get",
        path: `/devices/${deviceID}/history?${query.filter(Boolean).join("&")}`,
      });
    },
  },
};

interface Employee {
  name: string;
  phone: string;
  email: string;
}

export const employees = {
  get() {
    return makeRequest<Employee[]>({
      method: "get",
      path: "/employees",
    });
  },
};

export interface Beacon {
  description: string;
  make: string;
  model: string;
  uniqueID: string;
  beaconID: string;
  macAddress: string;
  proximityID: string;
  equipment: Equipment;
  equipmentID: string;
}

export interface BeaconDelete {
  beaconID: string;
}

export const beacons = {
  list() {
    return makeRequest<Beacon[]>({
      method: "get",
      path: "/beacons",
    });
  },
  get(id: string) {
    return makeRequest<Beacon>({
      method: "get",
      path: `beacons/${id}`,
    });
  },
  upsert(params: Partial<Beacon>) {
    const { beaconID, ...body } = params;
    return makeRequest<Beacon>({
      method: beaconID ? "put" : "post",
      path: beaconID ? `beacons/${beaconID}` : "beacons",
      body,
    });
  },
  delete(beaconID: string) {
    return makeRequest<BeaconDelete>({
      method: "delete",
      path: `/beacons/${beaconID}`,
    });
  },
};

export type Tenant = {
  id: string;
  name: string;
  createdAt: string;
  phoneNumber: string;
  contactEmail: string;
};

export const tenants = {
  list() {
    return makeRequest<Tenant[]>({
      method: "get",
      path: "/tenants",
    });
  },
  get(id: string) {
    return makeRequest<Tenant>({
      method: "get",
      path: `/tenants/${id}`,
    });
  },
  upsert(params: Partial<Tenant>) {
    const { id, ...body } = params;
    return makeRequest<Tenant>({
      method: id ? "put" : "post",
      path: id ? `tenants/${id}` : "tenants",
      body,
    });
  },
  switch(id: string) {
    return makeRequest<Tenant>({
      method: "get",
      path: `tenants/switch/${id}`,
    });
  }
};
