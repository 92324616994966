
import {computed, defineComponent, inject, provide, reactive, ref, watch} from "vue";
import AppLogo from "@/assets/logo.svg";
import IconEmployees from "@/assets/employee.svg";
import IconKnobs from "@/assets/knobs.svg";
import IconHelp from "@/assets/help.svg";
import IconProfile from "@/assets/profile.svg";
import { useRemoteData } from "@/hooks/useRemoteData";
import {Tenant, tenants, users} from "@/api";
import {useAsyncRef} from "@/hooks/useAysncComputed";
import {useRoute} from "vue-router";
import eventemitter from "eventemitter3";

export default defineComponent({
  name: "App",
  components: {
    AppLogo,
    IconEmployees,
    IconKnobs,
    IconHelp,
    IconProfile,
  },
  setup() {
    const navClassnames = reactive({ "p-8 border-r border-solid border-gray-200 flex flex-col justify-between w-72 flex-shrink-0": true, "print:hidden": false })

    const contentClassnames = reactive({ "flex-grow": true, "py-8": true, "px-16": true, "overflow-hidden": true, "print:hidden": false });
    provide("contentClassnames", contentClassnames);

    const contentAttributes = reactive({});
    provide("contentAttributes", contentAttributes);

    const route = useRoute();
    const hasSlideover = ref(false);
    watch(route, () => {
      hasSlideover.value = Object.keys(route?.matched[0]?.components).includes("slideover");
      navClassnames["print:hidden"] = hasSlideover.value;
      contentClassnames["print:hidden"] = hasSlideover.value;
    });

    const tenant = ref<Tenant>();
    const emitter = inject("eventemitter") as eventemitter;
    const onTenantUpdate = () => {
      tenants
        .get("current")
        .then(res => res.data)
        .then(t => (tenant.value = t))
    };
    emitter.on("tenant:update", onTenantUpdate);
    onTenantUpdate();

    return {
      tenant,
      navClassnames,
      contentClassnames,
      contentAttributes,
      hasSlideover,
      activeNavClass: "text-yellow-300",
    };
  },
});
