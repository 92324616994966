<template>
  <a href="#" @click="handleClose" class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-40 print:hidden"></a>
  <div
    :class="{
      'fixed top-0 right-0 h-screen bg-white p-8 space-y-8 overflow-auto print:w-screen print:static print:h-auto': true,
      'w-96': !width,
      'w-256 max-w-7/8': width === 'wide',
      'w-full': width === 'full',
    }"
  >
    <div class="flex justify-between print:hidden">
      <a href="#" @click="handleClose" class="block"><Close class="stroke-current w-4 h-4"></Close></a>
      <slot name="actions"></slot>
    </div>
    <div>
      <h1 class="text-lg"><slot name="title"></slot></h1>
      <slot name="subtitle"></slot>
    </div>
    <div v-if="width !== 'full'">
      <slot></slot>
    </div>
    <div v-if="width === 'wide'" class="flex">
      <div class="w-2/3 mr-8"><slot name="left"></slot></div>
      <div class="w-1/3"><slot name="right"></slot></div>
    </div>
    <div v-if="width === 'full'" class="flex">
      <div class="w-3/4"><slot></slot></div>
      <div class="w-1/4"><slot name="right"></slot></div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, toRef, watch} from "vue";
import Close from "@/assets/close.svg";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "DhSlideOver",
  props: {
    back: String,
    backParams: Object,
    width: String,
  },
  components: {
    Close,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const router = useRouter();

    const handleClose = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const closeEvent = new CustomEvent("close", { bubbles: true, cancelable: true });
      emit("close", closeEvent);
      if (!closeEvent.defaultPrevented) {
        router.push({ name: props.back, params: props.backParams });
      }
    };

    return {
      handleClose,
    };
  },
});
</script>
