<template>
  <dh-slide-over-form title="Job Type" back="jobtypes" @submit="handleSubmit" @delete="handleDelete" :showDelete="false">
    <dh-input label="Name" name="name" v-model="data.name" required></dh-input>
    <dh-input label="Priority Level" name="priorityLevel" v-model="data.priorityLevel"></dh-input>
  </dh-slide-over-form>
</template>

<script lang="ts">
import {defineComponent, inject} from "vue";
import DhInput from "../../components/DhInput.vue";
import DhSelect from "../../components/DhSelect.vue";
import DhSlideOverForm from "../../components/DhSlideOverForm.vue";
import {equipment, jobTypes, Equipment, beacons, JobType} from "@/api";
import { useRemoteData } from "@/hooks/useRemoteData";
import { useAsyncRef } from "@/hooks/useAysncComputed";
import { useRemoteMutation } from "@/hooks/useRemoteMutation";
import eventemitter from "eventemitter3";

export default defineComponent({
  name: "JobTypeForm",
  components: {
    DhSlideOverForm,
    DhInput,
  },
  setup() {
    const data = useRemoteData(jobTypes.get, {
      redirect: "jobtypes",
    });

    const handleSubmit = useRemoteMutation({
      redirect: "jobtypes",
      action: () => {
        const body: Partial<JobType> = {
          id: data.id,
          name: data.name,
          priorityLevel: data.priorityLevel,
        };
        return jobTypes.upsert(body);
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "jobTypes",
      method: "delete",
      action: () => jobTypes.delete(data.id).then(res => ({ id: res.data.jobTypeID })),
    });

    return {
      data,
      handleSubmit,
      handleDelete,
    };
  },
});
</script>
