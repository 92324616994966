<template>
  <dh-header>
    Ticket Report
    <template #buttons>
      <dh-button icon="export" variant="none" class="text-gray-500" @click="exportToCsv">Export to CSV</dh-button>
    </template>
  </dh-header>
  <div class="my-4">
    <p class="text-gray-500 text-sm">Filter</p>
    <select v-model="statusFilter" class="border border-gray-500 px-4 py-2 rounded-lg bg-gray-100">
      <option :value="undefined">Any Status</option>
      <option value="open">Open</option>
      <option value="closed">Closed</option>
    </select>
  </div>
  <dh-table :rows="rows" :loading="loading" row-route="ticketreports.detail" :row-params="row => ({ id: row.id })">
    <dh-table-column label="employee" :value="row => row.createdBy.username"></dh-table-column>
    <dh-table-column label="Location" :value="row => row.location?.name"></dh-table-column>
    <dh-table-column label="Equipment" :value="row => row.equipment?.name"></dh-table-column>
    <dh-table-column label="Start Time" v-slot="{ row }">
      <span v-if="row.timeStart">
        {{ format(parseISO(row.timeStart), "MM/d/yyyy") }}
        <br />
        {{ format(parseISO(row.timeStart), "h:mm a") }}
      </span>
    </dh-table-column>
    <dh-table-column label="Stop Time" v-slot="{ row }">
      <span v-if="row.timeEnd">
        {{ format(parseISO(row.timeEnd), "MM/d/yyyy") }}
        <br />
        {{ format(parseISO(row.timeEnd), "h:mm a") }}
      </span>
    </dh-table-column>
    <dh-table-column label="Job Type" :value="row => row.equipment?.jobType?.name"></dh-table-column>
    <dh-table-column label="Photos" v-slot="{ row }" class="w-28">
      <svg-check v-if="row?.availableImages?.length" class="w-4"></svg-check>
    </dh-table-column>
  </dh-table>
  <dh-pagination class="mt-8" :pages="pagination.pages" to="ticketreports"></dh-pagination>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import DhPagination from "@/components/DhPagination.vue";
import { tickets } from "@/api";
import { useRemoteList } from "@/hooks/useRemoteList";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import SvgCheck from "@/assets/check.svg";
import {useExportToCsv} from "@/hooks/useExportToCsv";

export default defineComponent({
  name: "TicketReport",
  components: {
    DhHeader,
    DhTable,
    DhTableColumn,
    DhButton,
    DhPagination,
    SvgCheck,
  },
  setup() {
    const statusFilter = ref<"open" | "closed" | undefined>();
    const [rows, pagination, loading] = useRemoteList(
      page => {
        return tickets.list(page, statusFilter.value);
      },
      { watch: [statusFilter] },
    );

    const exportToCsv = useExportToCsv({
      filename: "tickets.csv",
      action: () =>
        rows.value.map(row => ({
          Name: row.createdBy.username,
          Location: row.location?.name,
          Equipment: row.equipment?.name,
          "Start Time": row.timeStart,
          "Stop Time": row.timeEnd,
          "Job Type": row.equipment?.jobType?.name,
          "Photos": row?.availableImages?.length ? "√" : "",
        })),
    });

    return {
      rows,
      loading,
      exportToCsv,
      pagination,
      format,
      parseISO,
      statusFilter,
    };
  },
});
</script>
