<template>
  <component
    :is="route || to ? 'router-link' : href ? 'a' : 'button'"
    v-bind="boundProps"
    :class="{
      [classes]: true,
      'lds-ring': loading,
    }"
  >
    <template v-if="loading">
      &nbsp;
    </template>
    <template v-if="!loading">
      <icon-export v-if="icon === 'export'" class="w-3 inline-block stroke-current mr-2 align-baseline" />
      <icon-add v-if="icon === 'add'" class="w-3 inline-block stroke-current mr-2 align-baseline" />
      <icon-pencil v-if="icon === 'pencil'" class="w-3 inline-block stroke-current mr-2 align-baseline" />
      <icon-trash v-if="icon === 'trash'" class="w-3 inline-block stroke-current mr-2 align-baseline" />
      <slot></slot>
    </template>
  </component>
</template>

<style>
.lds-ring {
  position: relative;
}
.lds-ring:before,
.lds-ring:after {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 2em;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
  transform-origin: center center;
  transform: translate(-50%, -50%);
}
.lds-ring:before {
  animation-delay: -0.45s;
}
.lds-ring:after {
  animation-delay: -0.3s;
}
@keyframes lds-ring {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>

<script lang="ts">
import { defineComponent, toRef } from "vue";
import IconExport from "../assets/export.svg";
import IconAdd from "../assets/add.svg";
import IconPencil from "../assets/pencil.svg";
import IconTrash from "../assets/trash.svg";

export default defineComponent({
  name: "DhButton",
  props: {
    icon: String,
    to: Object,
    route: String,
    params: Object,
    href: String,
    variant: String,
    loading: Boolean,
  },
  components: {
    IconExport,
    IconAdd,
    IconPencil,
    IconTrash,
  },
  setup(props) {
    const boundProps: { to?: { name?: string; params?: object }, href?: string } = {};

    if (props.route) {
      boundProps.to = { name: props.route };
    }

    const to = toRef(props, "to");
    if (to.value) {
      boundProps.to = to.value as any;
    }

    const params = toRef(props, "params");
    if (params.value) {
      if (!boundProps.to) {
        throw Error("Trying to set route params without setting the route first");
      }
      boundProps.to.params = params.value;
    }

    const href = toRef(props, "href");
    if (href.value) {
      boundProps.href = href.value;
    }

    let classes = "py-2 px-6 inline-block uppercase";
    if (props.variant === "danger") {
      classes += " bg-red-200 text-red-800 rounded-full";
    } else if (props.variant === "none") {
      classes += "rounded-full";
    } else if (props.variant === "action") {
      classes += "text-center bg-yellow-300 rounded-lg w-full text-sm";
    } else {
      classes += " bg-gray-200 text-gray-800 rounded-full";
    }

    return {
      boundProps,
      classes,
    };
  },
});
</script>
