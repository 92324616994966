
import { defineComponent, ref } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import { Device, DeviceHistory, devices, users } from "@/api";
import { useRemoteList } from "@/hooks/useRemoteList";
import DhPagination from "@/components/DhPagination.vue";
import DhMap from "@/components/DhMap.vue";
import DhMapPolyline, { PolylinePoints } from "@/components/DhMapPolyline.vue";
import DhLabel from "@/components/DhLabel.vue";

export default defineComponent({
  name: "Devices",
  components: {
    DhPagination,
    DhHeader,
    DhTable,
    DhTableColumn,
    DhButton,
    DhMap,
    DhMapPolyline,
    DhLabel,
  },
  setup() {
    const deviceId = ref<string>();
    const history = ref<PolylinePoints[]>([]);
    const startDate = ref<HTMLInputElement>();
    const endDate = ref<HTMLInputElement>();
    const ownerId = ref<string | undefined>();
    const loadingMap = ref(false);
    const [owners] = useRemoteList(users.list);
    const [rows, pagination, loading] = useRemoteList(page => devices.list(page, ownerId.value), { watch: [ownerId] });

    // const defaultStart = [
    //     new Date().getUTCFullYear(),
    //     new Date().getUTCMonth(),
    //     new Date().getUTCDate(),
    // ].join('-');
    const defaultStart = new Date().toISOString();
    const defaultEndDate = new Date();
    defaultEndDate.setDate(new Date().getDate() - 1);
    const defaultEnd = defaultEndDate.toISOString();
    // const defaultEnd = [
    //   defaultEndDate.getUTCFullYear(),
    //   defaultEndDate.getUTCMonth(),
    //   defaultEndDate.getUTCDate(),
    // ].join('-');

    const updateHistory = () => {
      if (!deviceId.value) {
        return;
      }

      let start = undefined;
      if (startDate.value?.valueAsNumber) {
        start = startDate.value?.valueAsNumber / 1000;
      }

      let end = undefined;
      if (endDate.value?.valueAsNumber) {
        end = endDate.value?.valueAsNumber / 1000;
      }

      if (start === undefined || end === undefined || start < 0 || end < 0) {
        start = undefined;
        end = undefined;
      }

      loadingMap.value = true;
      devices.history
        .get(deviceId.value, start, end)
        .then(res => {
          history.value = res.data
            .filter(p => p.longitude != 0)
            .map(p => ({
              lat: p.latitude,
              lng: p.longitude,
              title: p.collectedDate,
              info: `
              <table>
                <tr><td>Location</td><td>${p.longitude}, ${p.latitude}</td></tr>
                <tr><td>Altitude</td><td>${p.altitude}</td></tr>
                <tr><td>Speed</td><td>${p.speed}</td></tr>
                <tr><td>Battery Level</td><td>${p.batterylevel}</td></tr>
                <tr><td>Connection</td><td>${p.internetConnectionType}</td></tr>
              </table>
            `,
            }));
        })
        .then(() => {
          loadingMap.value = false;
        });
    };

    const openEdit = (event: CustomEvent) => {
      const device = event.detail as Device;
      deviceId.value = device.deviceID;
      updateHistory();
    };

    const inputDateTime = (event: CustomEvent) => {
      updateHistory();
    };

    return { rows, pagination, loading, loadingMap, history, openEdit, inputDateTime, startDate, endDate, owners, ownerId, defaultStart, defaultEnd };
  },
});
