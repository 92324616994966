import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import EventEmitter from "eventemitter3";

import "./assets/style.css";

createApp(App)
  .use(store)
  .use(router)
  .provide("eventemitter", new EventEmitter())
  .mount("#app");
