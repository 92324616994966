
import {defineComponent, ref, toRef, watch} from "vue";
import Close from "@/assets/close.svg";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "DhSlideOver",
  props: {
    back: String,
    backParams: Object,
    width: String,
  },
  components: {
    Close,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const router = useRouter();

    const handleClose = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const closeEvent = new CustomEvent("close", { bubbles: true, cancelable: true });
      emit("close", closeEvent);
      if (!closeEvent.defaultPrevented) {
        router.push({ name: props.back, params: props.backParams });
      }
    };

    return {
      handleClose,
    };
  },
});
