
import { defineComponent } from "vue";
import DhSlideOverForm from "@/components/DhSlideOverForm.vue";
import DhInput from "@/components/DhInput.vue";

export default defineComponent({
  name: "TimeCardsCreate",
  components: {
    DhInput,
    DhSlideOverForm,
  },
  setup() {
    // return useRemoteData({ url: "/api/timecards.json", singular: true });
  },
});
