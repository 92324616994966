import {inject, reactive} from "vue";
import { useRouter, useRoute } from "vue-router";
import eventemitter from "eventemitter3";
import { ApiResponse } from "@/api";

/* eslint-disable @typescript-eslint/no-explicit-any */
export function useRemoteData<T extends object>(cb: (id: string) => Promise<ApiResponse<T>>, options: Record<string, any> = {}): T {
  const emitter = inject("eventemitter") as eventemitter;
  const initial = options?.initial || {};
  const data = reactive(initial) as T;
  const router = useRouter();
  const route = useRoute();
  const id = route.params?.id as string;
  if (id) {
    cb(id)
      .then(resolvedData => {
        Object.assign(data, resolvedData.data);
      })
      .catch(err => {
        emitter.emit("apiError", err);
        if (options?.redirect) {
          router.push({ name: options.redirect });
          setTimeout(() => alert("The requested data could not be found."), 500);
        }
      });
  }
  return data;
}
