<template>
  <div class="flex justify-between">
    <div class="space-y-4">
      <h1 class="text-xl font-bold"><slot></slot></h1>
      <div v-if="hasSubHead" class="text-xl font-hairline"><slot name="subhead"></slot></div>
    </div>
    <div>
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DhHeader",
  setup(props, { slots }) {
    const hasSubHead = slots.subhead && slots.subhead().length;
    return { hasSubHead };
  },
});
</script>
