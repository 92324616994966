
import {defineComponent, ref} from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import DhPagination from "@/components/DhPagination.vue";
import { tickets } from "@/api";
import { useRemoteList } from "@/hooks/useRemoteList";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import SvgCheck from "@/assets/check.svg";
import {useExportToCsv} from "@/hooks/useExportToCsv";

export default defineComponent({
  name: "TicketReport",
  components: {
    DhHeader,
    DhTable,
    DhTableColumn,
    DhButton,
    DhPagination,
    SvgCheck,
  },
  setup() {
    const statusFilter = ref<"open" | "closed" | undefined>();
    const [rows, pagination, loading] = useRemoteList(
      page => {
        return tickets.list(page, statusFilter.value);
      },
      { watch: [statusFilter] },
    );

    const exportToCsv = useExportToCsv({
      filename: "tickets.csv",
      action: () =>
        rows.value.map(row => ({
          Name: row.createdBy.username,
          Location: row.location?.name,
          Equipment: row.equipment?.name,
          "Start Time": row.timeStart,
          "Stop Time": row.timeEnd,
          "Job Type": row.equipment?.jobType?.name,
          "Photos": row?.availableImages?.length ? "√" : "",
        })),
    });

    return {
      rows,
      loading,
      exportToCsv,
      pagination,
      format,
      parseISO,
      statusFilter,
    };
  },
});
