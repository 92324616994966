<template>
  <dh-header>
    Manage Locations
    <template #buttons>
      <dh-button icon="export" variant="none" class="text-gray-500" @click="exportToCsv">Export to CSV</dh-button>
      <dh-button icon="add" variant="none" class="text-gray-500" route="locations.import">Import</dh-button>
      <dh-button icon="add" variant="none" class="text-yellow-300" route="locations.create">Add Location</dh-button>
    </template>
  </dh-header>
  <dh-table :rows="rows" :loading="loading">
    <dh-table-column label="Location" value="name"></dh-table-column>
    <dh-table-column label="Description" value="description"></dh-table-column>
    <dh-table-column label="Size" :value="row => `${Math.floor(row.area / 4_046.85642)} acres`" class="w-36"></dh-table-column>
    <dh-table-column label="Enabled" v-slot="{ row }" class="w-28">
      <svg-check v-if="row?.enabled" class="w-4"></svg-check>
    </dh-table-column>
    <template v-slot:edit="{ row }">
      <div class="space-y-4">
        <dl class="grid grid-cols-2">
          <dt class="row-start-1 font-bold text-sm uppercase text-gray-500 mb-2">Description</dt>
          <dd>{{ row.description }}</dd>
          <dt class="row-start-1 font-bold text-sm uppercase text-gray-500 mb-2">Contact Email</dt>
          <dd>{{ row.createdBy?.username }}</dd>
        </dl>
        <dh-map v-if="row.area" :disableDefaultUI="true">
          <dh-map-polygon :path="row.coordinateList.map(m => ({ lat: m[0], lng: m[1] }))" />
        </dh-map>
        <div class="text-right space-x-4">
          <dh-button icon="pencil" variant="info" route="locations.edit" :params="{ id: row.id }">Edit</dh-button>
          <dh-button icon="trash" variant="danger" @click="handleDelete" :data-id="row.id">Delete</dh-button>
        </div>
      </div>
    </template>
  </dh-table>
  <dh-pagination class="mt-8" :pages="pagination.pages" to="locations"></dh-pagination>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import DhMap from "@/components/DhMap.vue";
import DhPagination from "@/components/DhPagination.vue";
import { locations } from "@/api";
import DhMapPolygon from "@/components/DhMapPolygon.vue";
import {useRemoteList} from "@/hooks/useRemoteList";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import {useHandleDelete} from "@/hooks/useHandleDelete";
import {useExportToCsv} from "@/hooks/useExportToCsv";
import SvgCheck from "@/assets/check.svg";

export default defineComponent({
  name: "Location",
  components: {
    DhMapPolygon,
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
    DhMap,
    DhPagination,
    SvgCheck,
  },
  setup() {
    const [rows, pagination, loading] = useRemoteList(locations.geofences.list);

    const handleDelete = useRemoteMutation({
      method: "delete",
      action: async (event: MouseEvent) => {
        const id: string = (event.target as HTMLButtonElement).dataset.id as string;
        return locations.geofences.delete(id).then(res => ({data: {id: res.data.geofenceID}}));
      },
    });

    const exportToCsv = useExportToCsv({
      filename: "locations.csv",
      action: () =>
        rows.value.map(row => ({
          Name: row.name,
          Type: "",
          Product: "",
          Size: `${Math.floor(row.area / 4_046.85642)} acres`,
          Status: "",
        })),
    });

    return {
      rows,
      pagination,
      loading,
      exportToCsv,
      handleDelete,
    };
  },
});
</script>
