import {inject, reactive, ref, Ref, watch} from "vue";
import eventemitter from "eventemitter3";
import {ApiResponse, Pagination} from "@/api";
import {useRoute, useRouter} from "vue-router";

type UseRemoteListParams = {
  idField?: string;
  paginate?: boolean;
  watch?: Ref[];
};

export const useRemoteList = <T>(cb: (page: number) => Promise<ApiResponse<Array<T>>>, params?: UseRemoteListParams): [Ref<Array<T>>, Pagination, Ref<boolean>] => {
  const loading = ref(false);
  const route = useRoute();
  const router = useRouter();
  const { idField = "id", paginate = true } = params || {};
  const emitter = inject("eventemitter") as eventemitter;

  const pagination = reactive<Pagination>({
    currentPage: 1,
    items: 0,
    pages: 1,
    perPage: 0,
  });
  const data = (ref([]) as unknown) as Ref<Array<T>>;

  const update = (page: number) => {
    loading.value = true;
    cb(page)
      .then(res => {
        data.value = res.data;

        if (res.pagination) {
          Object.assign(pagination, res.pagination);
        }
      })
      .catch(err => {
        if (err.statusCode === 401) {
          delete localStorage.token;
          router.push({ name: "login" });
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };

  update(parseInt(route.query.page as string) || 1);

  if (params && params.watch && params.watch.length > 0) {
    params.watch.forEach(p => watch(p, () => update(1)));
  }

  if (paginate) {
    watch(
      () => route.query.page,
      async page => update(parseInt((page ? page : "1") as string)),
    );
  }

  emitter.on("model:update", needleObject => {
    const key = needleObject.key ? needleObject.key.split(".") : [];
    const needle = key?.[0] || needleObject[idField];
    const path = key.slice(1) as string[];
    const index = data.value.findIndex(row => (row as any)[idField] === needle);
    if (index >= 0) {
      if (path.length) {
        // @ts-ignore
        if (!data.value[index][path[0]]) {
          // @ts-ignore
          data.value[index][path[0]] = {};
        }
        // @ts-ignore
        Object.assign(data.value[index][path[0]], needleObject);
      } else {
        Object.assign(data.value[index], needleObject);
      }
    } else {
      if (path.length == 0) {
        //data.value.push(needleObject);
        update(parseInt(route.query.page as string) || 1);
      }
    }
  });

  emitter.on("model:delete", needle => {
    const index = data.value.findIndex(row => (row as any)[idField] === needle[idField]);
    if (index >= 0) {
      data.value.splice(index, 1);
    }
  });

  return [data, pagination, loading];
};
