
import { defineComponent, inject, Ref, toRef, watch } from "vue";
import { useMap } from "./DhMap.vue";
import { DeviceHistory } from "@/api";

export interface PolylinePoints {
  lat: number;
  lng: number;
  title?: string;
  info?: string;
}

export default defineComponent({
  name: "DhMapPolyline",
  props: {
    path: Object,
    editable: Boolean,
  },
  setup(props) {
    const pathRef = toRef(props, "path") as Ref<PolylinePoints[]>;

    (inject("map") as Promise<{ map: google.maps.Map }>).then(({ map }) => {
      const lineSymbol = {
        // eslint-disable-next-line no-undef
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      };

      // eslint-disable-next-line no-undef
      const polyline = new google.maps.Polyline({
        path: [],
        editable: false,
        icons: [
          {
            icon: lineSymbol,
            offset: "0%",
          },
          {
            icon: lineSymbol,
            offset: "25%",
          },
          {
            icon: lineSymbol,
            offset: "50%",
          },
          {
            icon: lineSymbol,
            offset: "75%",
          },
          {
            icon: lineSymbol,
            offset: "100%",
          },
        ],
      });

      polyline.setMap(map);

      watch(pathRef, path => {
        const cleanPath = path.map(({ lat, lng }) => ({ lat, lng }));

        polyline.setPath(cleanPath);

        path
          .filter(p => p.info)
          .map((p, index) => {
            // eslint-disable-next-line no-undef
            const infowindow = new google.maps.InfoWindow({
              content: p.info,
            });

            // eslint-disable-next-line no-undef
            // const marker = new google.maps.Marker({
            //   position: { lat: p.lat, lng: p.lng },
            //   map,
            //   title: p.title,
            //   label: `${index + 1}`,
            // });
            //
            // marker.addListener("click", () => {
            //   infowindow.open({
            //     anchor: marker,
            //     map,
            //     shouldFocus: false,
            //   });
            // });
          });

        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds();
        path.map(p => bounds.extend(p));
        map.fitBounds(bounds);
      });
    });

    return {};
  },
});
