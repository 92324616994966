<template>
  <form @submit="handleSubmit">
    <dh-slide-over :back="back" :back-params="backParams" :width="width" @close="e => $emit('close', e)">
      <template #title>
        <span v-if="title">{{ id ? "Edit" : "Add" }} {{ title }}</span>
        <slot name="title"></slot>
      </template>
      <div class="space-y-4">
        <slot></slot>
        <div v-if="!hasRight && showActions">
          <dh-button variant="action" :loading="loading">{{ id ? "Save" : "Add" }} {{ title }}</dh-button>
          <button v-if="id && showDelete" class="text-center p-3 rounded-lg w-full text-sm text-red-600" @click="handleDelete">Delete</button>
        </div>
      </div>
      <template #left><slot name="left"></slot></template>
      <template #right>
        <div class="space-y-4">
          <slot name="right"></slot>
          <div v-if="hasRight && showActions">
            <dh-button type="submit" variant="action" :loading="loading">{{ id ? "Save" : "Add" }} {{ title }}</dh-button>
            <button v-if="id && showDelete" class="text-center p-3 rounded-lg w-full text-sm text-red-600" @click="handleDelete">Delete</button>
          </div>
        </div>
      </template>
    </dh-slide-over>
  </form>
</template>


<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import DhSlideOver from "@/components/DhSlideOver.vue";
import DhButton from "@/components/DhButton.vue";
import { useHandleDelete } from "@/hooks/useHandleDelete";
import { useExtendedMouseEvent } from "@/hooks/useExtendedMouseEvent";

type WaitUntilMouseEvent = {
  waitUntil: (p: Promise<unknown>) => void;
};
export type ExtendedMouseEvent = MouseEvent & WaitUntilMouseEvent;

export default defineComponent({
  name: "DhSlideOverForm",
  props: {
    title: String,
    back: String,
    backParams: Object,
    width: String,
    showActions: {
      default: true,
      type: Boolean,
    },
    showDelete: {
      default: true,
      type: Boolean,
    },
  },
  components: {
    DhSlideOver,
    DhButton,
  },
  emits: ["submit", "delete", "close"],
  setup(props, { slots, emit }) {
    const route = useRoute();
    const id = route.params.id;
    const loading = ref(false);

    const [handleSubmit] = useExtendedMouseEvent({
      loading,
      action: (event: ExtendedMouseEvent) => {
        emit("submit", event);
      },
    });

    const [handleDelete] = useExtendedMouseEvent({
      loading,
      before: () => confirm(`Are you sure you want to delete this ${props.title}?`),
      action: (event: ExtendedMouseEvent) => {
        emit("delete", event);
      },
    });

    const hasRight = Boolean(slots.right && slots.right());

    return {
      id,
      handleSubmit,
      handleDelete,
      loading,
      hasRight,
    };
  },
});
</script>
