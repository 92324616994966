
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import DhMap from "@/components/DhMap.vue";
import DhPagination from "@/components/DhPagination.vue";
import { locations } from "@/api";
import DhMapPolygon from "@/components/DhMapPolygon.vue";
import {useRemoteList} from "@/hooks/useRemoteList";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import {useHandleDelete} from "@/hooks/useHandleDelete";
import {useExportToCsv} from "@/hooks/useExportToCsv";
import SvgCheck from "@/assets/check.svg";

export default defineComponent({
  name: "Location",
  components: {
    DhMapPolygon,
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
    DhMap,
    DhPagination,
    SvgCheck,
  },
  setup() {
    const [rows, pagination, loading] = useRemoteList(locations.geofences.list);

    const handleDelete = useRemoteMutation({
      method: "delete",
      action: async (event: MouseEvent) => {
        const id: string = (event.target as HTMLButtonElement).dataset.id as string;
        return locations.geofences.delete(id).then(res => ({data: {id: res.data.geofenceID}}));
      },
    });

    const exportToCsv = useExportToCsv({
      filename: "locations.csv",
      action: () =>
        rows.value.map(row => ({
          Name: row.name,
          Type: "",
          Product: "",
          Size: `${Math.floor(row.area / 4_046.85642)} acres`,
          Status: "",
        })),
    });

    return {
      rows,
      pagination,
      loading,
      exportToCsv,
      handleDelete,
    };
  },
});
