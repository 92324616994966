
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import DhMap from "@/components/DhMap.vue";
import { devices } from "@/api";
import DhMapPolyline from "@/components/DhMapPolyline.vue";

export default defineComponent({
  name: "DeviceHistory",
  components: {
  },
  setup() {
    const rows = devices.list();
    return { rows };
  },
});
