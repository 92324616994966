<template>
  <template v-if="!loading">
    <dh-table-data :rows="rows" :columns="columns" :rowRoute="rowRoute" :rowParams="rowParams" :idField="idField" @openEdit="toggleEdit" @closeEdit="toggleEdit">
      <template v-slot:edit="{ row }"><slot name="edit" v-bind:row="row"></slot></template>
    </dh-table-data>
  </template>
  <template v-if="loading">
    <div>
      <table class="w-full border-separate border-spacing-y-1">
        <dh-table-header :columns="columns"></dh-table-header>
        <tr>
          <td :colspan="columns.length" class="bg-gray-100 p-4 rounded-lg animate-pulse">&nbsp;</td>
        </tr>
        <tr>
          <td :colspan="columns.length" class="bg-gray-100 p-4 rounded-lg animate-pulse animation-delay-500">&nbsp;</td>
        </tr>
        <tr>
          <td :colspan="columns.length" class="bg-gray-100 p-4 rounded-lg animate-pulse animation-delay-1000">&nbsp;</td>
        </tr>
      </table>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, VNode, VNodeNormalizedChildren, VNodeArrayChildren, h } from "vue";
import DhTableData from "./DhTableData.vue";
import DhTableHeader from "./DhTableHeader.vue";

/* eslint-disable @typescript-eslint/no-explicit-any */
export default defineComponent({
  name: "DhTable",
  props: ["rows", "rowRoute", "rowParams", "idField", "loading"],
  components: {
    DhTableHeader,
    DhTableData,
  },
  emits: ["openEdit", "closeEdit"],
  setup(props, { slots, emit }) {
    let columns = [] as { label: string; getValue: (row: any) => string; getChildren: (row: any) => VNode }[];

    if (slots.default) {
      columns = slots
        .default()
        .filter((vNode: VNode) => {
          const type: any = vNode.type as any;
          return type.name === "DhTableColumn";
        })
        .map(({ props, children }) => ({
          label: props?.label,
          classes: props?.class,
          hasValue: () => !!props?.value,
          getValue: row => {
            if (typeof props?.value === "string") {
              return row[props?.value] as string;
            }

            if (typeof props?.value === "function") {
              return props?.value(row) as string;
            }

            return "";
          },
          hasChildren: () => Boolean(children),
          getChildren: row => {
            return (children as any).default({ row });
          },
        }));
    }

    const toggleEdit = (e: CustomEvent) => {
      if (["openEdit", "closeEdit"].includes(e.type)) {
        emit(e.type as "openEdit" | "closeEdit", e);
      }
    };

    return { columns, toggleEdit };
  },
});
</script>
