<template>
  <dh-slide-over-form :title="slideOverTitle" back="locations" :show-actions="!isEditing" @submit="handleSubmit" @delete="handleDelete" @close="handleClose" width="wide">
    <template #title v-if="isEditing">{{ data.coordinateList?.length ? 'Edit' : 'Draw' }} Geofence & Set Entrance</template>
    <div v-if="isEditing">
      <div class="space-y-4">
        <dh-input label="Location" placeholder="Search for a location" name="location" type="text" v-model="searchTerm"></dh-input>
        <dh-map :key="data.id" :center="center" :tall="true">
          <dh-map-polygon :editable="true" :path="data.coordinateList?.map(c => ({ lat: c[0], lng: c[1] }))" @update:path="handlePathUpdate" />
        </dh-map>
        <div class="text-center">
          <dh-button variant="action" @click="handleClose" class="max-w-xs">Add Geofence & Entrance</dh-button>
        </div>
      </div>
    </div>
    <template #right v-if="!isEditing">
      <dh-input label="Name" name="name" v-model="data.name" required></dh-input>
      <dh-textarea label="Description" name="description" v-model="data.description" required></dh-textarea>
      <dh-textarea label="Entrance" name="entrance" v-model="data.entranceDescription" rows="2" caption="Describe the entrance to this location."></dh-textarea>
    </template>
    <template #left v-if="!isEditing">
      <div class="space-y-4">
        <p>
          <button class="text-yellow-300 font-bold uppercase" @click="handleIsEditingClick">{{ data.coordinateList?.length ? 'Edit' : 'Draw' }} Geofence &amp; Set Entrance</button>
        </p>
        <dh-map :key="data.id" :center="center" :tall="true">
          <dh-map-polygon :path="data.coordinateList?.map(c => ({ lat: c[0], lng: c[1] }))" @update:path="handlePathUpdate" />
        </dh-map>
        <div>
          <dl class="mx-16 grid grid-cols-2 gap-6">
            <dt v-if="data.area">Acres</dt>
            <dd v-if="data.area" class="text-right font-bold">{{ Math.floor(data.area / 4_046.85642) }} acres</dd>
            <dt>Active Location</dt>
            <dd class="text-right font-bold">
              <div>
                <input class="peer sr-only" type="checkbox" v-model="data.enabled" name="enabled" id="enabled" />
                <label for="enabled" class="relative group inline-block bg-gray-400 transition-colors duration peer-checked:bg-yellow-300 rounded-full w-8 h-6">
                  <span class="sr-only">Enabled</span>
                  <span class="absolute top-1/2 transition duration -translate-y-1/2 left-4px w-4 h-4 bg-white group-peer-checked:translate-x-1/2 rounded-full"></span>
                </label>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </template>
  </dh-slide-over-form>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, watch } from "vue";
import DhInput from "@/components/DhInput.vue";
import DhButton from "@/components/DhButton.vue";
import DhSlideOverForm, { ExtendedMouseEvent } from "@/components/DhSlideOverForm.vue";
import DhMap from "@/components/DhMap.vue";
import { locations, LocationGeofence } from "@/api";
import { useRemoteData } from "@/hooks/useRemoteData";
import eventemitter from "eventemitter3";
import DhMapPolygon from "@/components/DhMapPolygon.vue";
import { useRouter } from "vue-router";
import debounce from "lodash.debounce";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import DhTextarea from "@/components/DhTextarea.vue";

export default defineComponent({
  name: "LocationForm",
  components: {
    DhTextarea,
    DhMapPolygon,
    DhSlideOverForm,
    DhInput,
    DhButton,
    DhMap,
  },
  setup() {
    const data = useRemoteData(locations.geofences.get, {
      redirect: "locations",
      initial: { enabled: true },
    });
    const searchTerm = ref("");
    const center = reactive({ lat: -34.397, lng: 150.644 });
    const isEditing = ref(false);
    const slideOverTitle = ref("Location");

    const debouncedWatcher = debounce((term: string) => {
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: term }, res => {
        const location = res?.[0]?.geometry?.location;
        if (location) {
          const [lat, lng] = [location.lat(), location.lng()];
          center.lat = lat;
          center.lng = lng;
        }
      });
    }, 1000);

    watch(searchTerm, term => debouncedWatcher(term));

    watch(isEditing, () => {
      slideOverTitle.value = (isEditing.value) ? "": "Location";
    });

    const handleIsEditingClick = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      isEditing.value = true;
    };

    const handleSubmit = useRemoteMutation({
      redirect: "locations",
      action: () => {
        const body: Partial<LocationGeofence> = {
          id: data.id,
          name: data.name,
          enabled: data.enabled,
          description: data.description,
          entranceDescription: data.entranceDescription,
        };
        if (data.coordinateList && data.coordinateList.length) {
          body.coordinateList = data.coordinateList;
        }
        return locations.geofences.upsert(body);
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "locations",
      method: "delete",
      action: () => locations.geofences.delete(data.id).then(res => ({ data: { id: res.data.geofenceID } })),
    });

    const handlePathUpdate = (path: google.maps.LatLngLiteral[]) => {
      data.coordinateList = path.map(p => [p.lat, p.lng]);
    };

    const handleClose = (event: MouseEvent) => {
      if (isEditing.value) {
        event.preventDefault();
        event.stopPropagation();
        isEditing.value = false;
      }
    };

    return {
      handleSubmit,
      handleDelete,
      handlePathUpdate,
      data,
      searchTerm,
      center,
      handleIsEditingClick,
      isEditing,
      slideOverTitle,
      handleClose,
    };
  },
});
</script>
