<template>
  <table class="w-full border-separate border-spacing-y-1 table-fixed">
    <dh-table-header :columns="columns"></dh-table-header>
    <tr v-if="rowDataRef.length === 0">
      <td :colspan="columns.length" class="bg-gray-100 rounded-lg p-4 text-gray-600">
        No results
      </td>
    </tr>
    <template v-for="(row, rowIndex) in rowDataRef" :key="row.id">
      <tr>
        <td
          v-for="(column, columnIndex) in columns"
          :key="rowIndex + columnIndex"
          :class="{
            'p-4': true,
            'cursor-pointer': isLinkable || isEditable,
            'bg-gray-100': selectedId !== row[idField],
            'bg-yellow-100': selectedId === row[idField],
            'border-transparent border-t-2 border-b-2': true,
            'border-l-2': columnIndex === 0,
            'border-r-2': columnIndex === columns.length - 1,
            'border-yellow-300': selectedId === row[idField],
            'rounded-l-lg': columnIndex === 0,
            'rounded-r-lg': columnIndex === columns.length - 1,
          }"
          :width="column.width"
          @click="event => rowClick(event, row, rowIndex)"
        >
          <div class="truncate" v-if="column.hasValue()">
            {{ column.hasValue() ? column.getValue(row) : "" }}
          </div>
          <div class="truncate" v-if="column.hasChildren()">
            <dh-v-nodes :vnodes="column.getChildren(row)"></dh-v-nodes>
          </div>
        </td>
      </tr>
      <tr v-if="isEditable && (editIndex === rowIndex || selectedId === row[idField])">
        <td class="p-4 pt-0" :colspan="columns.length">
          <div class="border-t py-8 px-12 border-gray-400">
            <slot name="edit" v-bind:row="row"></slot>
          </div>
        </td>
      </tr>
    </template>
  </table>
</template>

<script lang="ts">
import {defineComponent, ref, isRef, isReactive, inject, VNode, Ref, watch, toRef} from "vue";
import DhTableHeader from "./DhTableHeader.vue";
import {useRoute, useRouter} from "vue-router";
import eventemitter from "eventemitter3";
import DhVNodes from "@/components/DhVNodes.vue";

/* eslint-disable @typescript-eslint/no-explicit-any */
export default defineComponent({
  name: "DhTableData",
  props: {
    rows: Array,
    columns: Array,
    rowRoute: String,
    rowParams: Function,
    idField: {
      type: String,
      default: "id",
    },
  },
  components: {
    DhVNodes,
    DhTableHeader,
  },
  emits: ["openEdit", "closeEdit"],
  setup(props, { slots, emit }) {
    const rowData = [] as any;
    const editIndex = ref(-1);
    const router = useRouter();
    const route = useRoute();
    const selectedId = ref(route.params?.id);
    watch(
      () => route.params.id,
      val => (selectedId.value = val),
    );
    // const emitter = inject("eventemitter") as eventemitter;
    const isLinkable = !!props.rowRoute;

    let isEditable = false;
    if (slots.edit) {
      const slot = slots.edit({ row: {} }) as VNode[];
      isEditable = (slot[0]?.children?.length as number) > 0;
    }

    // // If a ref is passed in
    // if (isRef(props.rows)) {
    //   // (props.rows as Ref).value.forEach((row: any) => rowData.push(row));
    // }
    //
    // // If a reactive object is passed in
    // else if (isReactive(props.rows)) {
    //   // can't handle this
    // }
    //
    // // If a promise is passed in
    // else if (typeof props.rows === "object" && props.rows.then) {
    //   (await props.rows).forEach((row: any) => rowData.push(row));
    // }
    //
    // // If a function is passed in
    // else if (typeof props.rows === "function") {
    //   (await props.rows()).forEach((row: any) => rowData.push(row));
    // }
    //
    // // If a static array of rows is passed in
    // else if (typeof props.rows === "object") {
    //   props.rows.forEach((row: any) => rowData.push(row));
    // }
    //
    // // No idea what is passed in
    // else {
    //   throw Error("Could not get table data");
    // }

    // Convert our rowData to a reactive ref we can later mutate
    // const rowDataRef = isRef(props.rows) ? props.rows : ref(rowData);
    const rowDataRef = toRef(props, "rows");

    // Listen to updated by UUID
    // emitter.on(`model:update`, (e: any) => {
    //   if (!e.id) {
    //     return;
    //   }
    //
    //   const index = rowDataRef.value.findIndex((row: any) => row.id == e.id);
    //   if (index < 0) {
    //     return;
    //   }
    //
    //   // Object.assign(rowDataRef.value[index], e);
    //   for (const key of Object.keys(e)) {
    //     rowDataRef.value[index][key] = e[key];
    //   }
    // });

    const rowClick = (event: MouseEvent, row: any, rowIndex: number) => {
      if (isLinkable) {
        const params = props.rowParams ? props.rowParams(row) : {};
        router.push({ name: props.rowRoute, params });
      } else if (isEditable) {
        editIndex.value = editIndex.value === rowIndex ? -1 : rowIndex;

        const eventName = editIndex.value === -1 ? "closeEdit" : "openEdit";
        const toggleEdit = new CustomEvent(eventName, {
          bubbles: true,
          cancelable: true,
          detail: (rowDataRef.value?.[editIndex.value]),
        });
        emit(eventName, toggleEdit);
      }
    };

    return { selectedId, rowData, rowDataRef, editIndex, isLinkable, isEditable, rowClick };
  },
});
</script>
