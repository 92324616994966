<template>
  <dh-slide-over back="ticketreports">
    <template #actions>
      <div class="flex">
        <lock-icon v-if="data.timeEnd" class="w-4 h-4 mr-4" title="Locked"></lock-icon>
        <router-link v-if="!data.timeEnd" class="mr-4" :to="{ name: 'timecards.detail.edit', params: { id: data?.id }}" title="Edit"><pencil-icon class="w-4 h-4"/></router-link>
        <button @click="handlePrint" title="Print"><print-icon class="w-4 h-4"/></button>
      </div>
    </template>
    <template #title>
      <div class="space-y-4">
        <span v-if="data.timeEnd" class="block text-center rounded-full p-2 text-sm bg-gray-100 text-gray-500">Complete</span>
        <span v-if="!data.timeEnd" class="block text-center rounded-full p-2 text-sm bg-green-500 text-white">In Progress</span>
        <span class="block">View Ticket</span>
      </div>
    </template>
    <template #subtitle>
      <p class="text-sm text-gray-500">{{ data.id }}</p>
    </template>
    <div class="space-y-4">
      <div class="space-y-2 text-gray-500">
        <!-- @TODO add [in progress] pill -->
        <p>
          <strong class="text-black inline-block mr-2">Employee</strong>
          {{ data.createdBy?.individual?.firstName }}
          {{ data.createdBy?.individual?.lastName }}
          {{ !data.createdBy?.individual ? data.createdBy?.username : "" }}
        </p>
        <div class="grid grid-cols-2 gap-2">
          <p class="text-center">
            <strong class="block text-black">Job Type</strong>
            {{ data?.equipment?.jobType?.name }}
          </p>
          <p class="text-center">
            <strong class="block text-black">Date</strong>
            {{ data.timeStart && format(parseISO(data.timeStart), "MM/d/yyyy") }}
          </p>
          <p class="text-center">
            <strong class="block text-black">Start Time</strong>
            {{ data.timeStart && format(parseISO(data.timeStart), "h:mm a") }}
          </p>
          <p class="text-center">
            <strong class="block text-black">End Time</strong>
            {{ data.timeEnd && format(parseISO(data.timeEnd), "h:mm a") }}
          </p>
          <p class="text-center" v-if="data.lastModified">
            <strong class="block text-black">Last Edit</strong>
            {{ data.timeEnd && format(parseISO(data.lastModified), "h:mm a") }}
          </p>
          <p class="text-center" v-if="data.timeStart && data.timeEnd">
            <strong class="block text-black">Duration</strong>
            {{ formatDuration(intervalToDuration({ start: parseISO(data.timeStart), end: parseISO(data.timeEnd) })) }}
          </p>
          <p class="text-center" v-if="data.closedReason">
            <strong class="block text-black">Closed</strong>
            {{ data.closedReason }}
          </p>
        </div>
      </div>
      <hr v-if="data.questionAnswers && data.questionAnswers.length > 0" />
      <div class="space-y-1 grid gap-x-2 items-center grid-cols-[max-content,auto]" v-if="data.questionAnswers?.length">
        <equipment-summary-item
          v-for="answer in data.questionAnswers"
          :key="answer.id"
          :label="data.questions.find(q => q.id === answer.questionID).label"
        >
          {{
            ((data.questions || []).find(q => q.id === answer.questionID).choices || [])
              .filter(c => (answer.choices || []).includes(c.value))
              .map(c => c.display)
              .join(", ")
          }}
          {{ answer.answer }}
        </equipment-summary-item>
      </div>
      <hr v-if="data.notes" />
      <div class="space-y-2" v-if="data.notes">
        <p><strong class="inline-block mr-2 text-xl font-normal">Notes</strong></p>
        {{ data.notes }}
      </div>
      <hr v-if="data.equipment" />
      <div class="space-y-2" v-if="data.equipment">
        <p><strong class="inline-block mr-2 text-xl font-normal">Primary Equipment</strong></p>
        <equipment-summary :equipment="data.equipment"></equipment-summary>
      </div>
      <hr v-if="data.secondaryEquipment?.length" />
      <div class="space-y-2" v-if="data.secondaryEquipment?.length">
        <p><strong class="inline-block mr-2 text-xl font-normal">Secondary Equipment</strong></p>
        <div class="space-y-4 pl-4">
          <div v-for="secondaryEquipment in data.secondaryEquipment" :key="secondaryEquipment.id">
            <equipment-summary :equipment="secondaryEquipment"></equipment-summary>
          </div>
        </div>
      </div>
      <hr v-if="data.availableImages && data.availableImages.length > 0" />
      <div class="space-y-2" v-if="data.availableImages && data.availableImages.length > 0">
        <p><strong class="inline-block mr-2 text-xl font-normal">Photos</strong></p>
        <ul class="flex flex-wrap">
          <li v-for="image in data.availableImages" :key="image.id" class="mr-4 mb-4">
            <a :href="image.url" target="_blank">
              <img :src="image.url" width="50" />
            </a>
          </li>
        </ul>
      </div>
      <hr v-if="data.locationID" />
      <div class="space-y-2" v-if="data.locationID">
        <p><strong class="inline-block mr-2 text-xl font-normal">Location</strong></p>
        <div class="space-y-1 grid gap-x-2 items-center grid-cols-[max-content,auto]">
          <equipment-summary-item label="Name" v-if="data.location.name">{{ data.location.name }}</equipment-summary-item>
          <equipment-summary-item label="Description" v-if="data.location.description">{{ data.location.description }}</equipment-summary-item>
        </div>
        <!-- @TODO starting and ending location -->
        <dh-map class="print:hidden" :disable-default-u-i="false" :scroll-wheel="false" :center="{ lat: data.startingPoint[0], lng: data.startingPoint[1] }" :zoom="16">
          <dh-map-pin v-if="data.startingPoint" :point="{ lat: data.startingPoint[0], lng: data.startingPoint[1] }"></dh-map-pin>
          <dh-map-pin v-if="data.endingPoint" :point="{ lat: data.endingPoint[0], lng: data.endingPoint[1] }"></dh-map-pin>
        </dh-map>
      </div>
      <hr v-if="data.deviceID" />
      <div class="space-y-2" v-if="data.deviceID">
        <p><strong class="inline-block mr-2 text-xl font-normal">Device</strong></p>
        <p>{{ data.deviceID }}</p>
      </div>
      <button class="text-center p-3 rounded-lg w-full text-sm text-red-600 print:hidden" @click="handleDelete">Delete</button>
    </div>
  </dh-slide-over>
</template>

<script lang="ts">
import { computed, defineComponent, inject, Ref, ref, toRef, watch, watchEffect } from "vue";
import DhSlideOver from "@/components/DhSlideOver.vue";
import { useRemoteData } from "@/hooks/useRemoteData";
import { EquipmentQuestionAnswer, locations, tickets } from "@/api";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import DhMap from "@/components/DhMap.vue";
import EquipmentSummary from "@/views/admin/EquipmentSummary.vue";
import { useRemoteMutation } from "@/hooks/useRemoteMutation";
import { useHandleDelete } from "@/hooks/useHandleDelete";
import DhMapPin from "@/components/DhMapPin.vue";
import EquipmentSummaryItem from "@/views/admin/EquipmentSummaryItem.vue";
import PrintIcon from "@/assets/print.svg";
import PencilIcon from "@/assets/pencil.svg";
import LockIcon from "@/assets/lock.svg";

export default defineComponent({
  name: "TicketReportDetail",
  components: {
    EquipmentSummaryItem,
    DhMapPin,
    DhMap,
    DhSlideOver,
    EquipmentSummary,
    PrintIcon,
    PencilIcon,
    LockIcon,
  },
  setup(props, { emit }) {
    const data = useRemoteData(tickets.get, {
      redirect: "ticketreports",
      initial: {
        startingPoint: [0, 0],
        endingPoint: [0, 0],
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "ticketreports",
      method: "delete",
      action: () => {
        if (data.id) {
          return tickets.delete(data.id).then(res => ({ data: { id: res.data.ticketID } }));
        }
      },
    });

    const valuesForAnswer = (answer: EquipmentQuestionAnswer) => {
      // if (data?.questions?.length > 0) {
      //   data.questions
      //       .find(q => q.id === answer.questionID)
      //       ?.choices
      //       .filter(c => (answer.choices || []).contains(c.value))
      //       .map(c => c.display)
      //       .join(", ")
      // }
    };

    const handlePrint = (event: MouseEvent) => {
      event.preventDefault();
      window.print();
    };

    return {
      data,
      location,
      valuesForAnswer,
      parseISO,
      format,
      intervalToDuration,
      formatDuration,
      handleDelete,
      handlePrint,
    };
  },
});
</script>
