<template>
  <div class="md:flex">
    <div :class="{ ...navClassnames }">
      <div class="space-y-8">
        <app-logo class="w-44" />
        <p v-if="tenant" class="ml-8 text-yellow-300">
          {{ tenant.name }}
        </p>
        <ul class="space-y-8">
          <li class="space-y-4">
            <strong class="flex">
              <icon-employees class="w-4 h-full mr-4" />
              Employees
            </strong>
            <ul class="space-y-4 ml-8">
              <li><router-link to="/employees/manage" :active-class="activeNavClass">Manage Employees</router-link></li>
              <li><router-link to="/employees/time-cards" :active-class="activeNavClass">Time Cards</router-link></li>
              <li><router-link to="/employees/ticket-report" :active-class="activeNavClass">Ticket Report</router-link></li>
              <li><router-link to="/employees/devices" :active-class="activeNavClass">Devices</router-link></li>
            </ul>
          </li>
          <li class="space-y-4">
            <strong class="flex">
              <icon-knobs class="w-4 h-full mr-4" />
              Administration Settings
            </strong>
            <ul class="space-y-4 ml-8">
              <li><router-link to="/admin/equipment" :active-class="activeNavClass">Equipment</router-link></li>
              <li><router-link to="/admin/job-types" :active-class="activeNavClass">Job Types</router-link></li>
              <li><router-link to="/admin/locations" :active-class="activeNavClass">Location</router-link></li>
              <li><router-link to="/admin/products" :active-class="activeNavClass">Product</router-link></li>
              <li><router-link to="/admin/beacons" :active-class="activeNavClass">Beacon</router-link></li>
              <li><router-link to="/admin/pay-period" :active-class="activeNavClass">Pay Period</router-link></li>
            </ul>
          </li>
        </ul>
      </div>
      <ul class="space-y-4">
        <li class="flex">
          <icon-help class="w-4 h-full mr-4" />
          <a href="https://www.mydataharvest.com/help" target="_blank">Help</a>
        </li>
        <li class="flex">
          <icon-profile class="w-4 h-full mr-4" />
          <router-link to="/profile" :active-class="activeNavClass">Profile</router-link>
        </li>
      </ul>
    </div>
    <div :class="{ ...contentClassnames }" v-bind="contentAttributes">
      <router-view />
    </div>
    <router-view name="slideover" />
    <router-view name="slideover2" />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, provide, reactive, ref, watch} from "vue";
import AppLogo from "@/assets/logo.svg";
import IconEmployees from "@/assets/employee.svg";
import IconKnobs from "@/assets/knobs.svg";
import IconHelp from "@/assets/help.svg";
import IconProfile from "@/assets/profile.svg";
import { useRemoteData } from "@/hooks/useRemoteData";
import {Tenant, tenants, users} from "@/api";
import {useAsyncRef} from "@/hooks/useAysncComputed";
import {useRoute} from "vue-router";
import eventemitter from "eventemitter3";

export default defineComponent({
  name: "App",
  components: {
    AppLogo,
    IconEmployees,
    IconKnobs,
    IconHelp,
    IconProfile,
  },
  setup() {
    const navClassnames = reactive({ "p-8 border-r border-solid border-gray-200 flex flex-col justify-between w-72 flex-shrink-0": true, "print:hidden": false })

    const contentClassnames = reactive({ "flex-grow": true, "py-8": true, "px-16": true, "overflow-hidden": true, "print:hidden": false });
    provide("contentClassnames", contentClassnames);

    const contentAttributes = reactive({});
    provide("contentAttributes", contentAttributes);

    const route = useRoute();
    const hasSlideover = ref(false);
    watch(route, () => {
      hasSlideover.value = Object.keys(route?.matched[0]?.components).includes("slideover");
      navClassnames["print:hidden"] = hasSlideover.value;
      contentClassnames["print:hidden"] = hasSlideover.value;
    });

    const tenant = ref<Tenant>();
    const emitter = inject("eventemitter") as eventemitter;
    const onTenantUpdate = () => {
      tenants
        .get("current")
        .then(res => res.data)
        .then(t => (tenant.value = t))
    };
    emitter.on("tenant:update", onTenantUpdate);
    onTenantUpdate();

    return {
      tenant,
      navClassnames,
      contentClassnames,
      contentAttributes,
      hasSlideover,
      activeNavClass: "text-yellow-300",
    };
  },
});
</script>

<style>
#app {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
}
#app > * {
  width: 100%;
}
</style>
