
import { defineComponent, h } from "vue";

export default defineComponent({
  name: "DhOptionalElement",
  props: {
    enabled: Boolean,
    is: String,
  },
  setup(props, { slots }) {
    return () => {
      if (props.enabled && slots && slots.default) {
        return h(props.is || "div", {}, slots.default());
      }
      if (!props.enabled && slots && slots.default) {
        return slots.default();
      }
    };
  },
});
