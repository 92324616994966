
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";

export default defineComponent({
  name: "Help",
  components: {
    DhHeader,
  },
  setup() {
    return {};
  },
});
