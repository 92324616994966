<template>
  <div>
    <dh-label :id="id" :required="required">{{ label }}</dh-label>
    <textarea
      :id="input.id"
      :name="input.name"
      :class="`border-2 border-solid border-gray-400 rounded-lg p-2 w-full ${!rows && 'min-h-40'}`"
      :placeholder="input.placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :required="required"
      :rows="rows"
    />
    <p v-if="caption" class="text-xs text-gray-500">{{ caption }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DhLabel from "./DhLabel.vue";

let trackedInputs = 0;
export default defineComponent({
  name: "DhTextarea",
  props: {
    id: String,
    name: String,
    placeholder: String,
    label: String,
    modelValue: [String, Number],
    required: Boolean,
    caption: String,
    rows: Number,
  },
  components: {
    DhLabel,
  },
  emits: ["update:modelValue"],
  setup(props) {
    const passedName = props.name || `input-${trackedInputs++}`;
    const name = passedName;
    const id = props.id || passedName;
    const label = props.label || passedName;
    const placeholder = props.placeholder || label;

    return {
      input: {
        name,
        id,
        label,
        placeholder,
      },
    };
  },
});
</script>
