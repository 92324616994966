<template>
  <dh-slide-over-form title="Product" back="products" @submit="handleSubmit" @delete="handleDelete">
    <dh-input label="Name" name="name" v-model="data.name" required></dh-input>
    <dh-input label="Description" name="description" v-model="data.description" required></dh-input>
    <dh-select label="Type" name="type" v-model="data.type" :options="[{ value: 'commodity', label: 'Commodity' }, { value: 'product', label: 'Product' }]" required></dh-select>
    <dh-input label="Category" name="category" v-model="data.category" required></dh-input>
    <div class="grid grid-cols-[1fr,25px,1fr] items-center">
      <dh-input label="Purchase Unit Cost" name="purchaseUnitCost" v-model="data.purchaseUnitCost" required :contained="false" input-class-name="row-start-2"></dh-input>
      <span class="text-center text-gray-500 row-start-2">/</span>
      <dh-select label="Purchase Unit" name="purchaseUnit" v-model="data.purchaseUnit" model-key="identifier" :options="units" required :contained="false" label-class-name="col-start-3" select-class-name="row-start-2"></dh-select>
      <dh-select label="Distributed Unit Numerator" name="distributedUnitNumerator" v-model="data.distributedUnitNumerator" model-key="identifier" :options="units" required :contained="false" label-class-name="mt-4" select-class-name="row-start-4"></dh-select>
      <span class="text-center text-gray-500 row-start-4">/</span>
      <dh-select label="Distributed Unit Denominator" name="distributedUnitDenominator" v-model="data.distributedUnitDenominator" model-key="identifier" :options="units" required :contained="false" label-class-name="col-start-3 mt-4"></dh-select>
      <dh-select label="Conversion Unit Left" name="conversionUnitLeft" v-model="data.conversionUnitLeft" model-key="identifier" :options="units" required container-class-name="mt-4"></dh-select>
      <dh-select label="Conversion Unit Right" name="conversionUnitRight" v-model="data.conversionUnitRight" model-key="identifier" :options="units" required container-class-name="col-start-3 mt-4"></dh-select>
    </div>
  </dh-slide-over-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DhInput from "../../components/DhInput.vue";
import DhSlideOverForm from "../../components/DhSlideOverForm.vue";
import {useRemoteData} from "@/hooks/useRemoteData";
import * as api from "@/api";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import {Product} from "@/api";
import {useAsyncRef} from "@/hooks/useAysncComputed";
import DhSelect from "@/components/DhSelect.vue";

export default defineComponent({
  name: "ProductForm",
  components: {
    DhSlideOverForm,
    DhInput,
    DhSelect,
  },
  setup() {
    const data = useRemoteData(api.products.get, {
      redirect: "products",
    });
    const units = useAsyncRef(
      () =>
        api.units.list().then(units => {
          return Object.entries(units.data)
            .map(([, group]) => {
              return group.map(unit => ({ label: unit.name, value: unit.identifier }));
            })
            .flat();
        }),
      [],
    );

    const handleSubmit = useRemoteMutation({
      redirect: "products",
      action: () => {
        return api.products.upsert({
          id: data.id,
          name: data.name,
          description: data.description,
          type: data.type,
          category: data.category,
          purchaseUnitCost: data.purchaseUnitCost,
          purchaseUnit: data.purchaseUnit?.identifier,
          distributedUnitNumerator: data.distributedUnitNumerator?.identifier,
          distributedUnitDenominator: data.distributedUnitDenominator?.identifier,
          conversionUnitLeft: data.conversionUnitLeft?.identifier,
          conversionUnitRight: data.conversionUnitRight?.identifier,
        });
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "products",
      method: "delete",
      action: () => api.products.delete(data.id).then(res => ({ id: res.data.productID })),
    });

    return {
      data,
      units,
      handleSubmit,
      handleDelete,
    };
  },
});
</script>
