<template>
  <label class="text-gray-500 text-sm" :for="id">
    <slot></slot>
    {{ " " /* The slot element collapses whitespace so force some */ }}
    <span v-if="required" class="text-red-500">*</span>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DhLabel",
  props: {
    id: String,
    required: Boolean,
  },
});
</script>
