
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import DhSlideOver from "@/components/DhSlideOver.vue";
import DhButton from "@/components/DhButton.vue";
import { useHandleDelete } from "@/hooks/useHandleDelete";
import { useExtendedMouseEvent } from "@/hooks/useExtendedMouseEvent";

type WaitUntilMouseEvent = {
  waitUntil: (p: Promise<unknown>) => void;
};
export type ExtendedMouseEvent = MouseEvent & WaitUntilMouseEvent;

export default defineComponent({
  name: "DhSlideOverForm",
  props: {
    title: String,
    back: String,
    backParams: Object,
    width: String,
    showActions: {
      default: true,
      type: Boolean,
    },
    showDelete: {
      default: true,
      type: Boolean,
    },
  },
  components: {
    DhSlideOver,
    DhButton,
  },
  emits: ["submit", "delete", "close"],
  setup(props, { slots, emit }) {
    const route = useRoute();
    const id = route.params.id;
    const loading = ref(false);

    const [handleSubmit] = useExtendedMouseEvent({
      loading,
      action: (event: ExtendedMouseEvent) => {
        emit("submit", event);
      },
    });

    const [handleDelete] = useExtendedMouseEvent({
      loading,
      before: () => confirm(`Are you sure you want to delete this ${props.title}?`),
      action: (event: ExtendedMouseEvent) => {
        emit("delete", event);
      },
    });

    const hasRight = Boolean(slots.right && slots.right());

    return {
      id,
      handleSubmit,
      handleDelete,
      loading,
      hasRight,
    };
  },
});
