<template>
  <dh-header>
    Device History
    <template #buttons>
      <dh-button icon="export" variant="none" class="text-gray-500">Export to CSV</dh-button>
    </template>
  </dh-header>
  <dh-table :rows="rows">
    <dh-table-column
        label="Employee"
        :value="row => `${row.owner?.individual?.firstName || ''} ${row.owner?.individual?.lastName || ''}`"
    ></dh-table-column>
    <dh-table-column label="Device #" value="deviceID"></dh-table-column>
  </dh-table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import DhMap from "@/components/DhMap.vue";
import { devices } from "@/api";
import DhMapPolyline from "@/components/DhMapPolyline.vue";

export default defineComponent({
  name: "DeviceHistory",
  components: {
  },
  setup() {
    const rows = devices.list();
    return { rows };
  },
});
</script>
