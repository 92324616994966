import {Ref, ref} from "vue";
import {ExtendedMouseEvent} from "@/components/DhSlideOverForm.vue";

type UseExtendedMouseEventParams = {
  action: (event: ExtendedMouseEvent) => void;
  before?: () => boolean;
  after?: () => void;
  loading?: Ref<boolean>;
};

export const useExtendedMouseEvent = ({action, before, after, loading}: UseExtendedMouseEventParams) => {
  if (!loading) {
    loading = ref(false);
  }

  const handler = async (event: ExtendedMouseEvent) => {
    event.preventDefault();

    // @ts-ignore
    loading.value = true;

    if (before) {
      if (!before()) {
        // @ts-ignore
        loading.value = false;
        return;
      }
    }

    const promises: Promise<unknown>[] = [];
    event.waitUntil = p => promises.push(p);

    action(event);

    if (promises.length > 0) {
      Promise.all(promises)
        // @ts-ignore
        .then(() => (loading.value = false))
        .catch(e => {
          // @ts-ignore
          loading.value = false;
          alert("An error occurred! " + e);
        });
    } else {
      // @ts-ignore
      loading.value = false;
    }
  };

  return [handler, loading];
};
