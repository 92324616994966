
import { defineComponent, toRefs, ref, watch, computed } from "vue";
import DhLabel from "./DhLabel.vue";
import DhOptionalElement from "./DhOptionalElement.vue";

let trackedInputs = 0;
export default defineComponent({
  name: "DhInput",
  props: {
    id: String,
    name: String,
    placeholder: String,
    label: String,
    type: String,
    options: Object,
    modelValue: [String, Number],
    required: Boolean,
    contained: {
      type: Boolean,
      default: true,
    },
    inputClassName: {
      type: String,
      default: "",
    }
  },
  components: {
    DhLabel,
    DhOptionalElement,
  },
  emits: ["update:modelValue"],
  setup(props) {
    const passedName = props.name || `input-${trackedInputs++}`;
    const name = passedName;
    const id = props.id || passedName;
    const label = props.label || passedName;
    const placeholder = props.placeholder || label;
    const type = props.type || "text";
    const inputRef = ref<HTMLInputElement>();

    return {
      inputRef,
      input: {
        name,
        id,
        label,
        placeholder,
        type,
      },
    };
  },
});
