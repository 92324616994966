<template>
  <tr>
    <th v-for="(column, columnIndex) in columns" :key="columnIndex" :class="`text-left uppercase font-normal text-xs text-gray-500 p-4 pb-2 ${column.classes || ''}`">
      {{ column.label }}
    </th>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DhTableHeader",
  props: ["columns"],
});
</script>
