<template>
  <dh-header>
    Time Cards
    <template #buttons>
      <dh-button icon="export" variant="none" class="text-gray-500" @click="exportToCsv">Export to CSV</dh-button>
    </template>
  </dh-header>
  <div class="my-4 space-x-4 flex">
    <div>
      <p class="text-gray-500 text-sm">User</p>
      <select v-model="filterUserId" class="border border-gray-500 px-4 py-2 rounded-lg bg-gray-100">
        <option :value="undefined">All Users</option>
        <option v-for="user in allUsers" :value="user.id" :key="user.id">{{ user.username }}</option>
      </select>
    </div>
    <div>
      <p class="text-gray-500 text-sm">Date</p>
      <input type="date" v-model="filterDate" class="border border-gray-500 px-4 py-1 rounded-lg bg-gray-100" />
    </div>
  </div>
  <dh-table :rows="rows" :loading="loading" id-field="batchID" row-route="timecards.detail" :row-params="row => ({ id: row.batchID })">
    <dh-table-column label="Name" :value="row => row.createdBy.username"></dh-table-column>
    <dh-table-column label="Date" v-slot="{ row }"><dh-date-time :value="row.timeClockedIn" format="MM/d/yyyy"></dh-date-time></dh-table-column>
    <dh-table-column label="Total Time" v-slot="{ row }"><dh-duration :value="row.totalTime * 1000"></dh-duration></dh-table-column>
    <dh-table-column label="Ticket Count" :value="row => row.ticketList.length"></dh-table-column>
    <dh-table-column label="Lock Status" v-slot="{ row }">
      <svg-check v-if="row.postDate && row.postLocation" class="w-4"></svg-check>
    </dh-table-column>
  </dh-table>
  <dh-pagination class="mt-8" :pages="pagination.pages" to="timecards"></dh-pagination>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import DhDateTime from "@/components/DhDateTime.vue";
import DhDuration from "@/components/DhDuration.vue";
import DhPagination from "@/components/DhPagination.vue";
import { useRemoteList } from "@/hooks/useRemoteList";
import { tickets, users } from "@/api";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import { useExportToCsv } from "@/hooks/useExportToCsv";
import SvgCheck from "@/assets/check.svg";
import {parse} from "date-fns";

export default defineComponent({
  name: "TimeCards",
  components: {
    DhHeader,
    DhTable,
    DhTableColumn,
    DhButton,
    DhDateTime,
    DhDuration,
    DhPagination,
    SvgCheck,
  },
  setup() {
    const filterUserId = ref<string | undefined>();
    const filterDate = ref<string | undefined>();
    const [allUsers] = useRemoteList(users.list);

    const [rows, pagination, loading] = useRemoteList(
      page => {
        let postDate: string | undefined = undefined;
        if (filterDate.value) {
          const start = `${filterDate.value}T00:00:00.000Z`;
          const end = `${filterDate.value}T23:59:59.999Z`;
          postDate = `${start}|${end}`;
        }
        return tickets.batch.list(page, {
          userID: filterUserId.value,
          postDate,
        });
      },
      {
        idField: "batchID",
        watch: [filterUserId, filterDate],
      },
    );

    const exportToCsv = useExportToCsv({
      filename: "time-cards.csv",
      action: () =>
        rows.value.map(row => ({
          Name: row.createdBy.username,
          Date: row.timeClockedIn,
          "Total Time": row.totalTime,
          "Ticket Count": row.ticketList.length,
          "Lock Status": row.postDate && row.postLocation ? "🔒" : "",
        })),
    });

    return {
      rows,
      loading,
      exportToCsv,
      pagination,
      formatDuration,
      intervalToDuration,
      filterUserId,
      filterDate,
      allUsers,
    };
  },
});
</script>
