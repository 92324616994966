<template>
  <dh-slide-over back="timecards">
    <template #title>
      <div class="space-y-4">
        <span class="block">Time Card</span>
      </div>
    </template>
    <template #subtitle>
      <p class="text-sm text-gray-500">{{ data.batchID }}</p>
    </template>
    <template #actions>
      <span v-if="data.postDate && data.postLocation" title="Locked"><lock-icon class="w-4 h-4"></lock-icon></span>
    </template>
    <div class="space-y-4">
      <div class="space-y-2 text-gray-500">
        <p>
          <strong class="text-black inline-block mr-2">Employee</strong>
          {{ data.createdBy?.individual?.firstName }}
          {{ data.createdBy?.individual?.lastName }}
          {{ !data.createdBy?.individual ? data.createdBy?.username : "" }}
        </p>
        <div class="grid grid-cols-2 gap-2">
          <p class="text-center">
            <strong class="block text-black">Clocked In</strong>
            <dh-date-time :value="data.timeClockedIn" format="MM/dd/yyyy h:mma"></dh-date-time>
          </p>
          <p class="text-center">
            <strong class="block text-black">Clocked Out</strong>
            <dh-date-time :value="data.postDate" format="MM/dd/yyyy h:mma"></dh-date-time>
          </p>
          <p class="text-center">
            <strong class="block text-black">Total Time</strong>
            <dh-duration :value="data.totalTime * 1000"></dh-duration>
          </p>
        </div>
      </div>
      <hr v-if="data.ticketList?.length" />
      <div class="space-y-2" v-if="data.ticketList?.length">
        <p><strong class="inline-block mr-2 text-xl font-normal">Tickets</strong></p>
        <ul>
          <li v-for="ticket of data.ticketList" :key="ticket">
            <router-link :to="{ name: 'ticketreports.detail', params: { id: ticket } }" class="underline text-blue-500 hover:no-underline">
              {{ ticket }}
            </router-link>
          </li>
        </ul>
      </div>
      <hr v-if="data.deviceID" />
      <div class="space-y-2" v-if="data.deviceID">
        <p><strong class="inline-block mr-2 text-xl font-normal">Device</strong></p>
        <router-link :to="{ name: 'timecards.detail.device', params: { id: data.batchID } }" class="underline text-blue-500 hover:no-underline">
          <apple-icon v-if="device.make == 'ios'" class="w-4 h-4 inline mr-1 align-baseline"></apple-icon>
          <android-icon v-if="device.make == 'android'" class="w-4 h-4 inline mr-1 align-baseline"></android-icon>
          {{ device.model }}
          {{ !device.make && !device.model ? device.deviceID : '' }}
        </router-link>
      </div>
      <hr v-if="data.postLocation" />
      <div class="space-y-2" v-if="data.postLocation">
        <p><strong class="inline-block mr-2 text-xl font-normal">Location</strong></p>
        <div class="space-y-1 grid gap-x-2 items-center grid-cols-[min-content,auto]">
          <equipment-summary-item label="Date" v-if="data.postDate"><dh-date-time :value="data.postDate" format="MM/dd/yyyy h:mma"></dh-date-time></equipment-summary-item>
        </div>
        <dh-map
            :disable-default-u-i="false"
            :scroll-wheel="false"
            :center="{ lat: data.postLocation[0], lng: data.postLocation[1] }"
            :zoom="16"
        >
          <dh-map-pin v-if="data.postLocation" :point="{ lat: data.startLocation[0], lng: data.startLocation[1] }" label="A"></dh-map-pin>
          <dh-map-pin v-if="data.postLocation" :point="{ lat: data.postLocation[0], lng: data.postLocation[1] }" label="B"></dh-map-pin>
        </dh-map>
      </div>
    </div>
  </dh-slide-over>
</template>

<script lang="ts">
import {computed, defineComponent, reactive, watch} from "vue";
import DhSlideOver from "@/components/DhSlideOver.vue";
import { useRemoteData } from "@/hooks/useRemoteData";
import {tickets, devices, Device} from "@/api";
import DhMap from "@/components/DhMap.vue";
import DhMapPin from "@/components/DhMapPin.vue";
import EquipmentSummaryItem from "@/views/admin/EquipmentSummaryItem.vue";
import DhDateTime from "@/components/DhDateTime.vue";
import DhDuration from "@/components/DhDuration.vue";
import AppleIcon from "@/assets/apple.svg";
import AndroidIcon from "@/assets/android.svg";
import LockIcon from "@/assets/lock.svg";

export default defineComponent({
  name: "TimeCardsDetail",
  components: {
    DhDuration,
    DhDateTime,
    EquipmentSummaryItem,
    DhMapPin,
    DhMap,
    DhSlideOver,
    AppleIcon,
    AndroidIcon,
    LockIcon,
  },
  setup() {
    const data = useRemoteData(tickets.batch.get, {
      redirect: "timecards",
    });

    const device = reactive<Device | object>({});
    watch(
      () => data.deviceID,
      deviceID =>
        devices.get(deviceID).then(res => {
          Object.assign(device, res.data);
        }),
    );

    return {
      data,
      device,
    };
  },
});
</script>
