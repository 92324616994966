
import {defineComponent, inject, ref, watch} from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import {Equipment, equipment, jobTypes} from "@/api";
import { useRoute } from "vue-router";
import { useRemoteData } from "@/hooks/useRemoteData";
import eventemitter from "eventemitter3";
import {useRemoteList} from "@/hooks/useRemoteList";
import {useExportToCsv} from "@/hooks/useExportToCsv";

export default defineComponent({
  name: "Equipment",
  components: {
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
  },
  setup() {
    const [rows, pagination, loading] = useRemoteList(jobTypes.list);

    const exportToCsv = useExportToCsv({
      filename: "jobtypes.csv",
      action: () =>
        rows.value.map(row => ({
          Name: row.name,
          "Priority Level": row.priorityLevel,
        })),
    });

    return { rows, loading, exportToCsv };
  },
});
