
import { defineComponent, ref, onMounted, provide, inject, toRef, watch } from "vue";
import { defer } from "@/utils/defer";

type DhMapProps = Readonly<{}>;

type MapProvide = {
  map: google.maps.Map;
  bounds: google.maps.LatLngBounds;
};

export const useMap = async (): Promise<MapProvide> => {
  return inject("map") as NonNullable<MapProvide>;
};

const [api, setApi] = defer<boolean>();

declare global {
  interface Window {
    initMap: () => void;
  }
}

(window as Window)["initMap"] =
  (window as Window)["initMap"] ||
  function() {
    setApi(true);
  };

export default defineComponent({
  name: "DhMap",
  props: {
    disableDefaultUI: Boolean,
    center: Object,
    zoom: Number,
    scrollWheel: Boolean,
    tall: Boolean,
    loadingMap: Boolean,
  },
  setup(props) {
    const id = "dh-google-map";
    if (!document.getElementById(id)) {
      const key = "AIzaSyAXdGbl0wwJWTEidYhgFR7Rz5QMIHJ3qCI";
      const googleMapScript = document.createElement("SCRIPT");
      googleMapScript.id = id;
      googleMapScript.setAttribute("src", `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap`);
      googleMapScript.setAttribute("defer", "");
      googleMapScript.setAttribute("async", "");
      document.body.appendChild(googleMapScript);
    } else {
      setApi(true);
    }

    const map = ref();
    const [el, setEl] = defer<HTMLDivElement>();

    const mapPromise = Promise.all([el, api]).then(([el]) => {
      let theCenter = { lat: -34.397, lng: 150.644 } as google.maps.LatLngLiteral;
      if (props.center && props.center.lat && props.center.lng) {
        theCenter = props.center as google.maps.LatLngLiteral;
      }

      // eslint-disable-next-line no-undef
      const mapObj = new google.maps.Map(el as HTMLElement, {
        center: theCenter,
        zoom: props.zoom || 8,
        // @ts-ignore
        mapId: "f93096ef00892524",
        streetViewControl: false,
        fullscreenControl: false,
        disableDefaultUI: props.disableDefaultUI || false,
        scrollwheel: props.scrollWheel != undefined ? props.scrollWheel : true,
      });

      // eslint-disable-next-line no-undef
      const bounds = new google.maps.LatLngBounds();
      return {
        map: mapObj,
        bounds,
      };
    });
    provide("map", mapPromise);

    onMounted(() => {
      const mapEl = map.value;
      setEl(mapEl);
    });

    const centerRef = toRef(props, "center");
    watch(
      () => centerRef,
      center => {
        mapPromise.then(({ map }) => {
          map.setCenter(center.value as google.maps.LatLngLiteral);
        });
      },
      { deep: true },
    );

    const zoomRef = toRef(props, "zoom");
    watch(
      () => zoomRef,
      zoom => {
        mapPromise.then(({ map }) => {
          if (zoom.value) {
            map.setZoom(zoom.value);
          }
        });
      },
    );

    return {
      map,
    };
  },
});
