<template>
  <dh-slide-over-form title="Time Card" back="timecards" @submit="handleSubmit">
    <dh-input label="Employee" name="name" v-model="data.name"></dh-input>
    <dh-input label="Date" name="date" v-model="data.date"></dh-input>
    <dh-input label="Clock In Time" name="clockInTime" v-model="data.clockInTime"></dh-input>
    <dh-input label="Clock Out Time" name="clockOutTime" v-model="data.clockOutTime"></dh-input>
    <dh-input label="Clock In Notes" name="clockInNotes" v-model="data.clockInNotes"></dh-input>
    <dh-input label="Clock Out Notes" name="clockOutNotes" v-model="data.clockOutNotes"></dh-input>
  </dh-slide-over-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DhSlideOverForm from "@/components/DhSlideOverForm.vue";
import DhInput from "@/components/DhInput.vue";

export default defineComponent({
  name: "TimeCardsCreate",
  components: {
    DhInput,
    DhSlideOverForm,
  },
  setup() {
    // return useRemoteData({ url: "/api/timecards.json", singular: true });
  },
});
</script>
