import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

require.context("../views/", true, /\.vue$/);

const resourceRoutes = (path: string, name: string, view: string, routeNames: string[] = ["index", "create", "edit"]) => {
  const formView = view.replace(/\.vue$/, "Form.vue");
  const detailView = view.replace(/\.vue$/, "Detail.vue");

  const routes = [];
  if (routeNames.includes("index")) {
    routes.push({
      path,
      name,
      component: () => import(`../views/${view}`),
    });
  }

  if (routeNames.includes("create")) {
    routes.push({
      path: `${path}/create`,
      name: `${name}.create`,
      components: {
        default: () => import(`../views/${view}`),
        slideover: () => import(`../views/${formView}`),
      },
    });
  }

  if (routeNames.includes("edit")) {
    routes.push({
      path: `${path}/:id`,
      name: `${name}.edit`,
      components: {
        default: () => import(`../views/${view}`),
        slideover: () => import(`../views/${formView}`),
      },
    });
  }

  if (routeNames.includes("detail")) {
    routes.push({
      path: `${path}/:id`,
      name: `${name}.detail`,
      components: {
        default: () => import(`../views/${view}`),
        slideover: () => import(`../views/${detailView}`),
      },
    });
  }

  return routes;
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: { guest: true },
  },
  ...resourceRoutes("/employees/manage", "employees", "employees/Employees.vue"),
  ...resourceRoutes("/employees/time-cards", "timecards", "employees/TimeCards.vue", ["index", "detail"]),
  ...resourceRoutes("/employees/ticket-report", "ticketreports", "employees/TicketReport.vue", ["index", "create", "detail"]),
  ...resourceRoutes("/employees/devices", "devices", "employees/Devices.vue"),
  ...resourceRoutes("/employees/device-history", "devicehistory", "employees/DeviceHistory.vue"),
  ...resourceRoutes("/admin/equipment", "equipment", "admin/Equipment.vue"),
  ...resourceRoutes("/admin/job-types", "jobtypes", "admin/JobType.vue"),
  ...resourceRoutes("/admin/locations", "locations", "admin/Location.vue"),
  ...resourceRoutes("/admin/products", "products", "admin/Product.vue"),
  ...resourceRoutes("/admin/beacons", "beacons", "admin/Beacon.vue"),
  {
    path: "/admin/locations/import",
    name: "locations.import",
    components: {
      default: () => import(`../views/admin/Location.vue`),
      slideover: () => import("../views/admin/LocationImport.vue"),
    },
  },
  {
    path: "/employees/time-cards/:id/device",
    name: "timecards.detail.device",
    components: {
      default: () => import(`../views/employees/TimeCards.vue`),
      slideover: () => import("../views/employees/TimeCardsDetail.vue"),
      slideover2: () => import("../views/employees/DeviceDetail.vue"),
    },
  },
  {
    path: "/employees/ticket-report/:id/edit",
    name: "timecards.detail.edit",
    components: {
      default: () => import(`../views/employees/TicketReport.vue`),
      slideover: () => import("../views/employees/TicketReportDetail.vue"),
      slideover2: () => import("../views/employees/TicketReportForm.vue"),
    },
  },
  {
    path: "/admin/pay-period",
    name: "payperiod",
    component: () => import("../views/admin/PayPeriod.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/utils/Help.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/utils/Profile.vue"),
  },
  {
    path: "/profile/tenant",
    name: "tenant.create",
    components: {
      default: () => import(`../views/utils/Profile.vue`),
      slideover: () => import("../views/utils/TenetForm.vue"),
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.guest === true) {
    return next();
  }

  if (localStorage.token) {
    return next();
  }

  return next({
    path: "/login",
  });
});

export default router;
