import { createStore } from "vuex";

export default createStore({
  state: {
    // models: {} as any,
  },
  mutations: {
    // upsertModel(state, model) {
    //   if (model.id) {
    //     state.models[model.id] = model;
    //   }
    // },
  },
  actions: {},
  modules: {},
});
