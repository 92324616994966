<template>
  <dh-slide-over-form title="Employee" back="employees" @submit="handleSubmit" @delete="handleDelete">
    <dh-input label="First Name" name="firstName" v-model="individual.firstName" required></dh-input>
    <dh-input label="Last Name" name="lastName" v-model="individual.lastName" required></dh-input>
    <dh-input label="Email" name="email" type="email" v-model="user.username" required></dh-input>
    <dh-input label="Password" name="password" type="password" v-model="user.password" :required="!user.id"></dh-input>
    <dh-input label="Phone" name="phone" type="phone" v-model="individual.phoneNumber" required></dh-input>
    <dh-input label="Employee ID" name="employeeId" v-model="employeeData.employeeNumber"></dh-input>
    <dh-input label="Hourly Rate" name="hourlyRate" v-model="employeeData.rate"></dh-input>
    <dh-select
      label="Type"
      name="type"
      v-model="employeeData.type"
      required
      :options="[
        { label: 'Part Time', value: 'Part Time' },
        { label: 'Full Time', value: 'Full Time' },
        { label: 'Contractor', value: 'Contractor' },
      ]"
    ></dh-select>
    <dh-select
      label="Employee Status"
      name="employeeStatus"
      v-model="user.status"
      required
      :options="[
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Inactive', value: 'INACTIVE' },
        { label: 'Locked', value: 'LOCKED' },
      ]"
    ></dh-select>
    <dh-select label="Role" name="role" v-model="user.roleName" :options="roles"></dh-select>
  </dh-slide-over-form>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from "vue";
import DhInput from "../../components/DhInput.vue";
import DhSelect from "../../components/DhSelect.vue";
import DhSlideOverForm from "../../components/DhSlideOverForm.vue";
import { ApiResponse, Individual, individuals, User, users } from "@/api";
import { useRemoteData } from "@/hooks/useRemoteData";
import { useRemoteMutation } from "@/hooks/useRemoteMutation";
import eventemitter from "eventemitter3";
import { useRemoteList } from "@/hooks/useRemoteList";
import { useAsyncRef } from "@/hooks/useAysncComputed";

export default defineComponent({
  name: "EmployeesCreate",
  components: {
    DhSlideOverForm,
    DhInput,
    DhSelect,
  },
  setup() {
    const user = useRemoteData(id => users.get(id));
    const individual = computed(() => user?.individual || {});
    const employeeData = computed(() => user?.employeeData || {});
    // const roles = useAsyncRef(() =>
    //   users.getRoles().then(res =>
    //     res.data.map(role => ({
    //       label: role,
    //       value: role,
    //     })),
    //   ),
    // );
    const roles = [
      { label: "User", value: "User" },
      { label: "Admin", value: "Admin" },
      { label: "None", value: "None" },
    ];

    const handleSubmit = useRemoteMutation({
      redirect: "employees",
      action: async () => {
        const body = {
          id: user.id,
          status: user.status,
          username: user.username,
          employeeData: {
            employeeNumber: employeeData.value.employeeNumber,
            rate: employeeData.value.rate,
            type: employeeData.value.type,
          },
          roleName: user.roleName,
        } as Partial<User>;

        if (user.password) {
          body.password = user.password;
        }

        const userResponse = await users.upsert(body);
        const userId: string = userResponse.data.id;

        if (!individual.value.id) {
          const userWithoutIndividual: ApiResponse<Partial<User>> = userResponse;
          delete userWithoutIndividual.data.individual;
          Object.assign(user, userWithoutIndividual);
        }

        const individualResponse = individuals.upsert({
          id: individual.value?.id,
          userID: userId,
          firstName: individual.value.firstName,
          lastName: individual.value.lastName,
          phoneNumber: individual.value.phoneNumber,
          type: "Employee",
        } as Partial<Individual>);

        return users.get(userId);
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "employees",
      method: "delete",
      action: () => users.delete(user.id).then(res => ({ data: { id: res.data.userID }})),
    });

    return {
      user,
      individual,
      employeeData,
      handleSubmit,
      handleDelete,
      roles,
    };
  },
});
</script>
