type UseExportToCsvParams = {
  filename?: string;
  action: () => Array<Record<string, string | number | undefined>>;
};

export const useExportToCsv = ({ action, filename }: UseExportToCsvParams) => {
  return () => {
    const rowData = action();
    const headers = Object.keys(rowData[0]);
    const data = [headers.join(","), ...rowData.map(row => Object.values(row).join(","))].join("\r\n");

    const anchor = document.createElement("a");
    anchor.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(data));
    anchor.setAttribute("download", filename || "export.csv");
    anchor.click();
  };
};
