
import { defineComponent, inject, reactive, ref, watch } from "vue";
import DhInput from "@/components/DhInput.vue";
import DhButton from "@/components/DhButton.vue";
import DhSlideOverForm, { ExtendedMouseEvent } from "@/components/DhSlideOverForm.vue";
import DhMap from "@/components/DhMap.vue";
import { locations, LocationGeofence } from "@/api";
import { useRemoteData } from "@/hooks/useRemoteData";
import eventemitter from "eventemitter3";
import DhMapPolygon from "@/components/DhMapPolygon.vue";
import { useRouter } from "vue-router";
import debounce from "lodash.debounce";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import DhTextarea from "@/components/DhTextarea.vue";

export default defineComponent({
  name: "LocationForm",
  components: {
    DhTextarea,
    DhMapPolygon,
    DhSlideOverForm,
    DhInput,
    DhButton,
    DhMap,
  },
  setup() {
    const data = useRemoteData(locations.geofences.get, {
      redirect: "locations",
      initial: { enabled: true },
    });
    const searchTerm = ref("");
    const center = reactive({ lat: -34.397, lng: 150.644 });
    const isEditing = ref(false);
    const slideOverTitle = ref("Location");

    const debouncedWatcher = debounce((term: string) => {
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: term }, res => {
        const location = res?.[0]?.geometry?.location;
        if (location) {
          const [lat, lng] = [location.lat(), location.lng()];
          center.lat = lat;
          center.lng = lng;
        }
      });
    }, 1000);

    watch(searchTerm, term => debouncedWatcher(term));

    watch(isEditing, () => {
      slideOverTitle.value = (isEditing.value) ? "": "Location";
    });

    const handleIsEditingClick = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      isEditing.value = true;
    };

    const handleSubmit = useRemoteMutation({
      redirect: "locations",
      action: () => {
        const body: Partial<LocationGeofence> = {
          id: data.id,
          name: data.name,
          enabled: data.enabled,
          description: data.description,
          entranceDescription: data.entranceDescription,
        };
        if (data.coordinateList && data.coordinateList.length) {
          body.coordinateList = data.coordinateList;
        }
        return locations.geofences.upsert(body);
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "locations",
      method: "delete",
      action: () => locations.geofences.delete(data.id).then(res => ({ data: { id: res.data.geofenceID } })),
    });

    const handlePathUpdate = (path: google.maps.LatLngLiteral[]) => {
      data.coordinateList = path.map(p => [p.lat, p.lng]);
    };

    const handleClose = (event: MouseEvent) => {
      if (isEditing.value) {
        event.preventDefault();
        event.stopPropagation();
        isEditing.value = false;
      }
    };

    return {
      handleSubmit,
      handleDelete,
      handlePathUpdate,
      data,
      searchTerm,
      center,
      handleIsEditingClick,
      isEditing,
      slideOverTitle,
      handleClose,
    };
  },
});
