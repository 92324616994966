
import { defineComponent } from "vue";

export default defineComponent({
  name: "DhLabel",
  props: {
    id: String,
    required: Boolean,
  },
});
