
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import {beacons, locations} from "@/api";
import {useRemoteList} from "@/hooks/useRemoteList";
import {useHandleDelete} from "@/hooks/useHandleDelete";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import {useExportToCsv} from "@/hooks/useExportToCsv";

export default defineComponent({
  name: "Beacon",
  components: {
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
  },
  setup() {
    const [rows, pagination, loading] = useRemoteList(beacons.list, { idField: "beaconID" });

    const handleDelete = useRemoteMutation({
      method: "delete",
      idField: "beaconID",
      action: async event => {
        const id: string = (event.target as HTMLButtonElement).dataset.id as string;
        return beacons.delete(id);
      },
    });

    const exportToCsv = useExportToCsv({
      filename: "beacons.csv",
      action: () =>
        rows.value.map(row => ({
          Make: row.make,
          Model: row.model,
          Equipment: row.equipment?.name,
          "Activation Date": row.equipment?.createdAt,
          "Battery Life": "",
          Status: "",
        })),
    });

    return {
      rows,
      loading,
      exportToCsv,
      handleDelete,
    };
  },
});
