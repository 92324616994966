<template>
  <dh-header>
    Manage Equipment
    <template #buttons>
      <dh-button icon="export" variant="none" class="text-gray-500" @click="exportToCsv">Export to CSV</dh-button>
      <dh-button icon="add" variant="none" class="text-yellow-300" route="equipment.create">Add Equipment</dh-button>
    </template>
  </dh-header>
  <dh-table :rows="rows" :loading="loading" row-route="equipment.edit" :row-params="row => ({ id: row.id })">
    <dh-table-column label="Equipment" :value="row => row.name"></dh-table-column>
    <!-- some day vue will support optional chaining, https://github.com/vuejs/vue/issues/11088 -->
    <dh-table-column label="Job Type" :value="row => row.jobType && row.jobType.name"></dh-table-column>
    <dh-table-column label="Make & Model" :value="row => `${row.make} ${row.model}`"></dh-table-column>
    <dh-table-column label="Year" :value="row => row.year"></dh-table-column>
    <dh-table-column label="Operation Cost" :value="row => row.operationCost"></dh-table-column>
    <dh-table-column label="Task Priority" :value="() => 'Task Priority'"></dh-table-column>
    <dh-table-column label="Beacon #" :value="row => row.beacon?.beaconID"></dh-table-column>
  </dh-table>
</template>

<script lang="ts">
import {defineComponent, inject, ref, watch} from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import {Equipment, equipment} from "@/api";
import { useRoute } from "vue-router";
import { useRemoteData } from "@/hooks/useRemoteData";
import eventemitter from "eventemitter3";
import {useRemoteList} from "@/hooks/useRemoteList";
import {useExportToCsv} from "@/hooks/useExportToCsv";

export default defineComponent({
  name: "Equipment",
  components: {
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
  },
  setup() {
    const [rows, pagination, loading] = useRemoteList(equipment.list);

    const exportToCsv = useExportToCsv({
      filename: "equipment.csv",
      action: () =>
        rows.value.map(row => ({
          Equipment: row.name,
          "Job Type": row.jobType?.name,
          "Make & Model": [row.make, row.model].filter(Boolean).join(" "),
          Year: row.year,
          "Operation Cost": row.operationCost,
          "Task Priority": "Task Priority",
          "Beacon #": row.beacon?.beaconID,
        })),
    });

    return { rows, loading, exportToCsv };
  },
});
</script>
