<template>
  <dh-slide-over back="timecards.detail" :back-params="{id: timecard.batchID}" width="wide">
    <h1 class="text-2xl">Device Detail</h1>
    <p>
      Entire day of
      <dh-date-time :value="timecard.timeClockedIn" format="MM/dd/yyyy"></dh-date-time>
    </p>
    <dh-map class="mt-4" tall :loading-map="loadingMap">
      <dh-map-polyline :path="history"></dh-map-polyline>
    </dh-map>
  </dh-slide-over>
</template>

<script lang="ts">
import DhSlideOver from "@/components/DhSlideOver.vue";
import {defineComponent, ref, watch} from "vue";
import {useRemoteData} from "@/hooks/useRemoteData";
import {devices, tickets} from "@/api"
import DhMap from "@/components/DhMap.vue";
import DhDateTime from "@/components/DhDateTime.vue";
import dateFnsParseISO from "date-fns/parseISO";
import {endOfDay, getTime, startOfDay} from "date-fns";
import DhMapPolyline, {PolylinePoints} from "@/components/DhMapPolyline.vue";

export default defineComponent({
  name: "TicketReportDetail",
  components: {
    DhMap,
    DhSlideOver,
    DhDateTime,
    DhMapPolyline,
  },
  setup(props, {emit}) {
    const timecard = useRemoteData(tickets.batch.get, {
      redirect: "timecards",
    });
    const history = ref<Array<PolylinePoints>>([]);
    const loadingMap = ref(false);

    watch(
      () => timecard.deviceID,
      deviceID => {
        if (!deviceID) {
          return;
        }

        const timeClockedIn = dateFnsParseISO(timecard.timeClockedIn);
        const start = Math.floor(getTime(startOfDay(timeClockedIn))/1000);
        const end = Math.floor(getTime(endOfDay(timeClockedIn))/1000);
        loadingMap.value = true;
        devices.history.get(timecard.deviceID, start, end).then(res => {
          history.value = res.data
            .filter(p => p.longitude !== 0)
            .map(p => ({
              lat: p.latitude,
              lng: p.longitude,
            }));
          loadingMap.value = false;
        });
        return [];
      },
    );

    return {
      timecard,
      history,
      loadingMap,
    };
  },
});
</script>
