<template>
  <dh-slide-over-form title="Equipment" back="equipment" @submit="handleSubmit" @delete="handleDelete" width="full">
    <div class="flex gap-8 mr-8">
      <div class="w-1/3 space-y-4">
        <dh-input label="Name" name="name" v-model="data.name" required></dh-input>
        <dh-input label="Description" name="description" v-model="data.description"></dh-input>
        <dh-input label="Year" name="year" v-model="data.year"></dh-input>
        <dh-input label="Make" name="make" v-model="data.make"></dh-input>
        <dh-input label="Model" name="model" v-model="data.model"></dh-input>
      </div>
      <div class="w-1/3 space-y-4">
        <dh-input label="Operation Cost" name="operationCost" v-model="data.operationCost"></dh-input>
        <dh-select label="Job Type" name="jobType" :options="jobTypeOptions" v-model="data.jobType" model-key="id" required></dh-select>
        <dh-select label="Beacon" name="beacon" :options="beaconOptions" v-model="data.beacon" model-key="beaconID"></dh-select>
      </div>
      <div class="w-1/3 space-y-4">
        <div class="space-y-2">
          <p>Ticket Information:</p>
          <p>Choose what information you would like employees to record while using this piece of equipment. Any question selected will be required for the user to fill out before they can submit their ticket.</p>
        </div>
        <ul class="space-y-4">
          <li v-for="question in questions" :key="question.label">
            <label>
              <input type="checkbox" :value="question.id" class="sr-only" v-model="selectedQuestionIds" />
              <span class="block bg-gray-100 rounded p-4 border-2 border-transparent peer-checked:border-yellow-400">{{ question.label }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <template #right></template>
  </dh-slide-over-form>
</template>

<script lang="ts">
import { defineComponent, inject, ref, watch } from "vue";
import DhInput from "../../components/DhInput.vue";
import DhSelect from "../../components/DhSelect.vue";
import DhSlideOverForm from "../../components/DhSlideOverForm.vue";
import { equipment, jobTypes, Equipment, beacons, EquipmentPut, EquipmentQuestion } from "@/api";
import { useRemoteData } from "@/hooks/useRemoteData";
import { useAsyncRef } from "@/hooks/useAysncComputed";
import { useRemoteMutation } from "@/hooks/useRemoteMutation";
import eventemitter from "eventemitter3";

export default defineComponent({
  name: "EquipmentForm",
  components: {
    DhSlideOverForm,
    DhInput,
    DhSelect,
  },
  setup() {
    const data = useRemoteData(equipment.get, {
      redirect: "equipment",
    });
    const jobTypeOptions = useAsyncRef(async () => (await jobTypes.list()).data.map(j => ({ value: j.id, label: j.name })));
    const beaconOptions = useAsyncRef(async () => {
      const beaconData = (await beacons.list()).data;
      return beaconData.map(b => ({
        value: b.beaconID,
        label: `${b.make} ${b.model} (${b.uniqueID})`,
        disabled: true,
      }));
    });
    const questions = useAsyncRef(async () => (await equipment.questions.list()).data);
    const selectedQuestionIds = ref<Array<string>>([]);
    watch(
      () => data.equipmentQuestions,
      (questions: EquipmentQuestion[]) => {
        selectedQuestionIds.value = questions.map(question => question.id);
      },
    );

    const handleSubmit = useRemoteMutation({
      redirect: "equipment",
      action: () => {
        const body: Partial<EquipmentPut> = {
          id: data.id,
          name: data.name,
          description: data.description,
          year: data.year,
          make: data.make,
          model: data.model,
          operationCost: data.operationCost,
          jobTypeID: data.jobType?.id,
          becaonReferenceID: data.beacon?.beaconID,
          questionIDs: selectedQuestionIds.value,
          deleteQuestions: true,
        };
        return equipment.upsert(body);
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "equipment",
      method: "delete",
      action: () => equipment.delete(data.id).then(res => ({ id: res.data.equipmentID })),
    });

    return {
      data,
      beaconOptions,
      jobTypeOptions,
      questions,
      selectedQuestionIds,
      handleSubmit,
      handleDelete,
    };
  },
});
</script>
