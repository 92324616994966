
import { defineComponent, ref } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhButton from "@/components/DhButton.vue";
import background from "@/assets/payperiod.svg";
import {reports} from "@/api";

export default defineComponent({
  name: "PayPeriod",
  components: {
    DhButton,
    DhHeader,
    svgBackground: background,
  },
  setup() {
    const fromInput = ref();
    const toInput = ref();

    const handleExport = async (event: MouseEvent) => {
      event.preventDefault();

      let from = undefined;
      if (fromInput.value?.valueAsDate) {
        from = [
          fromInput.value?.valueAsDate.getUTCFullYear(),
          fromInput.value?.valueAsDate.getUTCMonth()+1,
          fromInput.value?.valueAsDate.getUTCDate(),
        ].join("-");
      }

      let to = undefined;
      if (toInput.value?.valueAsDate) {
        to = [
          toInput.value?.valueAsDate.getUTCFullYear(),
          toInput.value?.valueAsDate.getUTCMonth() + 1,
          toInput.value?.valueAsDate.getUTCDate(),
        ].join("-");
      }

      if (!from || !to) {
        return;
      }

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const result = await reports.pay(from, to, timezone);

      const blob = new Blob([(result as any)], { type: "text/csv" });

      const a = document.createElement("a");
      a.download = `pay-period-${from}-${to}.csv`;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500);
    };

    return { fromInput, toInput, handleExport };
  },
});
