<template>
  <dh-slide-over-form title="Ticket" back="ticketreports.detail" :back-params="{ id: data.id }" @submit="handleSubmit">
    <dh-select
      label="Employee"
      name="employee"
      v-model="data.createdBy"
      model-key="id"
      required
      :options="employees.map(e => ({ value: e.id, label: e.individual ? [e.individual.firstName, e.individual.lastName].join(' ') : e.username }))"
    ></dh-select>
    <dh-input label="Start Time" name="timeStart" type="datetime-local" v-model="timeStart" required></dh-input>
    <dh-input label="End Time" name="timeEnd" type="datetime-local" v-model="timeEnd" required></dh-input>
  </dh-slide-over-form>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from "vue";
import DhSlideOverForm from "@/components/DhSlideOverForm.vue";
import DhInput from "@/components/DhInput.vue";
import DhSelect from "@/components/DhSelect.vue";
import * as api from "@/api";
import { useRemoteData } from "@/hooks/useRemoteData";
import { useRemoteList } from "@/hooks/useRemoteList";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { zonedTimeToUtc } from "date-fns-tz";
import { useRemoteMutation } from "@/hooks/useRemoteMutation";
import { Ticket } from "@/api";
import {parse} from "date-fns";

export default defineComponent({
  name: "TicketReportForm",
  components: {
    DhSelect,
    DhInput,
    DhSlideOverForm,
  },
  setup() {
    const data = useRemoteData(id => api.tickets.get(id));
    const timeStart = computed({
      get: () => (data.timeStart ? format(parseISO(data.timeStart), "yyyy-MM-dd'T'HH:mm") : ""),
      set: (val: string) => (data.timeStart = format(parseISO(val), "yyyy-MM-dd'T'HH:mm:ssxxx")),
    });
    const timeEnd = computed({
      get: () => (data.timeEnd ? format(parseISO(data.timeEnd), "yyyy-MM-dd'T'HH:mm") : ""),
      set: (val: string) => (data.timeEnd = format(parseISO(val), "yyyy-MM-dd'T'HH:mm:ssxxx")),
    });

    const [employees] = useRemoteList(api.users.list);
    const [equipment] = useRemoteList(api.equipment.list);
    const [locations] = useRemoteList(() => api.locations.geofences.list(1, 1000));

    const handleSubmit = useRemoteMutation({
      redirect: "ticketreports",
      action: async () => {
        const timeStart = parse(parseISO(data.timeStart).toUTCString(), "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
        const timeEnd = parse(parseISO(data.timeEnd).toUTCString(), "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());
        if (timeStart > timeEnd) {
          throw Error("Start time must be before end time");
        }

        const body = {
          id: data.id,
          timeStart: format(timeStart, "yyyy-MM-dd'T'HH:mm:ss'-00:00'"),
          timeEnd: format(timeEnd, "yyyy-MM-dd'T'HH:mm:ss'-00:00'"),
        } as Partial<Ticket>;
        return api.tickets.upsert(body);
      },
    });

    return {
      handleSubmit,
      data,
      employees,
      equipment,
      locations,
      timeStart,
      timeEnd,
    };
  },
});
</script>
