
import { computed, defineComponent, inject } from "vue";
import DhInput from "../../components/DhInput.vue";
import DhSelect from "../../components/DhSelect.vue";
import DhSlideOverForm from "../../components/DhSlideOverForm.vue";
import { ApiResponse, Individual, individuals, User, users } from "@/api";
import { useRemoteData } from "@/hooks/useRemoteData";
import { useRemoteMutation } from "@/hooks/useRemoteMutation";
import eventemitter from "eventemitter3";
import { useRemoteList } from "@/hooks/useRemoteList";
import { useAsyncRef } from "@/hooks/useAysncComputed";

export default defineComponent({
  name: "EmployeesCreate",
  components: {
    DhSlideOverForm,
    DhInput,
    DhSelect,
  },
  setup() {
    const user = useRemoteData(id => users.get(id));
    const individual = computed(() => user?.individual || {});
    const employeeData = computed(() => user?.employeeData || {});
    // const roles = useAsyncRef(() =>
    //   users.getRoles().then(res =>
    //     res.data.map(role => ({
    //       label: role,
    //       value: role,
    //     })),
    //   ),
    // );
    const roles = [
      { label: "User", value: "User" },
      { label: "Admin", value: "Admin" },
      { label: "None", value: "None" },
    ];

    const handleSubmit = useRemoteMutation({
      redirect: "employees",
      action: async () => {
        const body = {
          id: user.id,
          status: user.status,
          username: user.username,
          employeeData: {
            employeeNumber: employeeData.value.employeeNumber,
            rate: employeeData.value.rate,
            type: employeeData.value.type,
          },
          roleName: user.roleName,
        } as Partial<User>;

        if (user.password) {
          body.password = user.password;
        }

        const userResponse = await users.upsert(body);
        const userId: string = userResponse.data.id;

        if (!individual.value.id) {
          const userWithoutIndividual: ApiResponse<Partial<User>> = userResponse;
          delete userWithoutIndividual.data.individual;
          Object.assign(user, userWithoutIndividual);
        }

        const individualResponse = individuals.upsert({
          id: individual.value?.id,
          userID: userId,
          firstName: individual.value.firstName,
          lastName: individual.value.lastName,
          phoneNumber: individual.value.phoneNumber,
          type: "Employee",
        } as Partial<Individual>);

        return users.get(userId);
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "employees",
      method: "delete",
      action: () => users.delete(user.id).then(res => ({ data: { id: res.data.userID }})),
    });

    return {
      user,
      individual,
      employeeData,
      handleSubmit,
      handleDelete,
      roles,
    };
  },
});
