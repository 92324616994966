
import {defineComponent, reactive, ref} from "vue";
import { useRouter } from "vue-router";
import DhHeader from "@/components/DhHeader.vue";
import DhButton from "@/components/DhButton.vue";
import {useRemoteData} from "@/hooks/useRemoteData";
import {useRemoteList} from "@/hooks/useRemoteList";
import {Beacon, beacons, Tenant, tenants as tenantApi} from "@/api";
import DhInput from "@/components/DhInput.vue";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import {useExtendedMouseEvent} from "@/hooks/useExtendedMouseEvent";
import DhSlideOverForm from "@/components/DhSlideOverForm.vue";

export default defineComponent({
  name: "Profile",
  components: {
    DhInput,
    DhSlideOverForm,
  },
  setup() {
    const newTenant = reactive({ name: "", phoneNumber: "", email: "" });

    const handleSubmit = useRemoteMutation({
      redirect: "profile",
      action: () => {
        return tenantApi.upsert(newTenant);
      },
    });

    return {
      newTenant,
      handleSubmit,
    };
  },
});
