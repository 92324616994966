
import { defineComponent } from "vue";
import DhInput from "../../components/DhInput.vue";
import DhSlideOverForm from "../../components/DhSlideOverForm.vue";
import {useRemoteData} from "@/hooks/useRemoteData";
import * as api from "@/api";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";
import {Product} from "@/api";
import {useAsyncRef} from "@/hooks/useAysncComputed";
import DhSelect from "@/components/DhSelect.vue";

export default defineComponent({
  name: "ProductForm",
  components: {
    DhSlideOverForm,
    DhInput,
    DhSelect,
  },
  setup() {
    const data = useRemoteData(api.products.get, {
      redirect: "products",
    });
    const units = useAsyncRef(
      () =>
        api.units.list().then(units => {
          return Object.entries(units.data)
            .map(([, group]) => {
              return group.map(unit => ({ label: unit.name, value: unit.identifier }));
            })
            .flat();
        }),
      [],
    );

    const handleSubmit = useRemoteMutation({
      redirect: "products",
      action: () => {
        return api.products.upsert({
          id: data.id,
          name: data.name,
          description: data.description,
          type: data.type,
          category: data.category,
          purchaseUnitCost: data.purchaseUnitCost,
          purchaseUnit: data.purchaseUnit?.identifier,
          distributedUnitNumerator: data.distributedUnitNumerator?.identifier,
          distributedUnitDenominator: data.distributedUnitDenominator?.identifier,
          conversionUnitLeft: data.conversionUnitLeft?.identifier,
          conversionUnitRight: data.conversionUnitRight?.identifier,
        });
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "products",
      method: "delete",
      action: () => api.products.delete(data.id).then(res => ({ id: res.data.productID })),
    });

    return {
      data,
      units,
      handleSubmit,
      handleDelete,
    };
  },
});
