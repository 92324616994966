
import { computed, defineComponent, inject, Ref, ref, toRef, watch, watchEffect } from "vue";
import DhSlideOver from "@/components/DhSlideOver.vue";
import { useRemoteData } from "@/hooks/useRemoteData";
import { EquipmentQuestionAnswer, locations, tickets } from "@/api";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import DhMap from "@/components/DhMap.vue";
import EquipmentSummary from "@/views/admin/EquipmentSummary.vue";
import { useRemoteMutation } from "@/hooks/useRemoteMutation";
import { useHandleDelete } from "@/hooks/useHandleDelete";
import DhMapPin from "@/components/DhMapPin.vue";
import EquipmentSummaryItem from "@/views/admin/EquipmentSummaryItem.vue";
import PrintIcon from "@/assets/print.svg";
import PencilIcon from "@/assets/pencil.svg";
import LockIcon from "@/assets/lock.svg";

export default defineComponent({
  name: "TicketReportDetail",
  components: {
    EquipmentSummaryItem,
    DhMapPin,
    DhMap,
    DhSlideOver,
    EquipmentSummary,
    PrintIcon,
    PencilIcon,
    LockIcon,
  },
  setup(props, { emit }) {
    const data = useRemoteData(tickets.get, {
      redirect: "ticketreports",
      initial: {
        startingPoint: [0, 0],
        endingPoint: [0, 0],
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "ticketreports",
      method: "delete",
      action: () => {
        if (data.id) {
          return tickets.delete(data.id).then(res => ({ data: { id: res.data.ticketID } }));
        }
      },
    });

    const valuesForAnswer = (answer: EquipmentQuestionAnswer) => {
      // if (data?.questions?.length > 0) {
      //   data.questions
      //       .find(q => q.id === answer.questionID)
      //       ?.choices
      //       .filter(c => (answer.choices || []).contains(c.value))
      //       .map(c => c.display)
      //       .join(", ")
      // }
    };

    const handlePrint = (event: MouseEvent) => {
      event.preventDefault();
      window.print();
    };

    return {
      data,
      location,
      valuesForAnswer,
      parseISO,
      format,
      intervalToDuration,
      formatDuration,
      handleDelete,
      handlePrint,
    };
  },
});
