<template>
  <dh-header>
    Manage Products
    <template #buttons>
      <dh-button icon="export" variant="none" class="text-gray-500" @click="exportToCsv">Export to CSV</dh-button>
      <dh-button icon="add" variant="none" class="text-yellow-300" route="products.create">Add Product</dh-button>
    </template>
  </dh-header>
  <dh-table :rows="rows" :loading="loading" row-route="products.edit" :row-params="row => ({ id: row.id })">
    <dh-table-column label="Name" value="name"></dh-table-column>
    <dh-table-column label="Type" value="type"></dh-table-column>
  </dh-table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import { useRemoteList } from "@/hooks/useRemoteList";
import { products } from "@/api";
import {useExportToCsv} from "@/hooks/useExportToCsv";

export default defineComponent({
  name: "Product",
  components: {
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
  },
  setup() {
    const [rows, pagination, loading] = useRemoteList(products.list);

    const exportToCsv = useExportToCsv({
      filename: "products.csv",
      action: () =>
        rows.value.map(product => ({
          Name: product.name,
          Type: product.type,
        })),
    });

    return { rows, loading, exportToCsv };
  },
});
</script>
