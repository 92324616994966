
import {defineComponent, ref, watch, computed, toRef} from "vue";
import { useRoute } from "vue-router";

const clamp = (min: number, value: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

export default defineComponent({
  name: "DhPagination",
  props: {
    pages: { type: Number, required: true },
    to: String,
  },
  components: {},
  setup(props) {
    const route = useRoute();

    const activePage = ref(route.query.page ? parseInt(route.query.page as string) : 1);
    watch(
      () => route.query.page,
      page => (activePage.value = page ? parseInt(page as string) : 1),
    );

    const pagesRef = toRef(props, "pages");
    const pageLinks = computed(() => {
      const allPageLinks: number[] = [];

      if (pagesRef.value === 0) {
        return allPageLinks;
      }

      allPageLinks.push(1);

      if (pagesRef.value > 2) {
        const start = clamp(2, activePage.value - 5, pagesRef.value - 1);
        const end = clamp(2, activePage.value + 5, pagesRef.value - 1);
        for (let i = start; i <= end; i++) {
          allPageLinks.push(i);
        }
      }

      if (pagesRef.value > 1) {
        allPageLinks.push(pagesRef.value);
      }

      return allPageLinks;
    });

    const scrollToTop = () => {
      window.scrollTo(0,0);
    };

    return {
      activePage,
      pageLinks,
      scrollToTop,
    };
  },
});
