
import { defineComponent } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import { users } from "@/api";
import { useRemoteList } from "@/hooks/useRemoteList";
import {useExportToCsv} from "@/hooks/useExportToCsv";

export default defineComponent({
  name: "Employees",
  components: {
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
  },
  setup() {
    const [rows, pagination, loading] = useRemoteList(users.list);

    const exportToCsv = useExportToCsv({
      filename: "users.csv",
      action: () =>
        rows.value.map(user => ({
          Name: [user.individual?.firstName, user.individual?.lastName].filter(Boolean).join(" "),
          Phone: user.individual?.phoneNumber,
          Email: user.username,
          Type: user.employeeData?.type,
          Status: user.status,
        })),
    });

    return { rows, loading, exportToCsv };
  },
});
