
import {defineComponent, inject, ref} from "vue";
import { useRouter } from "vue-router";
import DhInput from "@/components/DhInput.vue";
import { auth } from "@/api";
import DhButton from "@/components/DhButton.vue";
import eventemitter from "eventemitter3";

export default defineComponent({
  name: "Login",
  components: {
    DhButton,
    DhInput,
  },
  setup() {
    const router = useRouter();
    const username = ref("");
    const password = ref("");
    const emitter = inject("eventemitter") as eventemitter;

    return {
      username,
      password,
      async onSubmit(event: MouseEvent) {
        event.preventDefault();
        try {
          const response = await auth.token({ username, password });
          localStorage.token = response.data.token;
          router.push({ name: "home" });
          emitter.emit("tenant:update");
        } catch (error) {
          alert(error.message);
        }
      },
    };
  },
});
