
import {defineComponent, ref, watch} from "vue";
import DhInput from "../../components/DhInput.vue";
import DhSelect from "../../components/DhSelect.vue";
import DhSlideOverForm from "../../components/DhSlideOverForm.vue";
import { useAsyncRef } from "@/hooks/useAysncComputed";
import {beacons, equipment, Beacon} from "@/api";
import {useRemoteData} from "@/hooks/useRemoteData";
import {useRemoteMutation} from "@/hooks/useRemoteMutation";

export default defineComponent({
  name: "BeaconForm",
  components: {
    DhSlideOverForm,
    DhInput,
    DhSelect,
  },
  setup() {
    const data = useRemoteData(beacons.get, {
      redirect: "beacons",
    });
    const equipmentOptions = useAsyncRef(async () => (await equipment.list()).data.map(e => ({ value: e.id, label: e.name })));

    const handleSubmit = useRemoteMutation({
      redirect: "beacons",
      idField: "beaconID",
      action: () => {
        const body: Partial<Beacon> = {
          beaconID: data.beaconID,
          description: data.description,
          equipmentID: data.equipment?.id,
          macAddress: data.macAddress,
          proximityID: data.proximityID,
          make: data.make,
          model: data.model,
          uniqueID: data.uniqueID,
        };
        return beacons.upsert(body);
      },
    });

    const handleDelete = useRemoteMutation({
      redirect: "beacons",
      method: "delete",
      idField: "beaconID",
      action: () => beacons.delete(data.beaconID),
    });

    return {
      data,
      equipmentOptions,
      handleSubmit,
      handleDelete,
    };
  },
});
