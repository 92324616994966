
import {ComponentPropsOptions, computed, defineComponent, inject, Ref, ref, toRef, toRefs, watch} from "vue";

export default defineComponent({
  name: "DhMapPolygon",
  props: {
    path: {
      type: ([Array, Promise] as unknown) as () => google.maps.LatLngLiteral[],
    },
    editable: Boolean,
  },
  emits: ["update:path"],
  setup(props, { emit }) {
    // const mapRef = ref() as Ref<google.maps.Map | null>;
    // const boundsRef = ref() as Ref<google.maps.LatLngBounds | null>;
    (inject("map") as Promise<{ map: google.maps.Map; bounds: google.maps.LatLngBounds }>).then(({ map, bounds }) => {
      const path = (props.path as unknown) as google.maps.LatLngLiteral[];
      const pathRef = toRef(props, "path");
      // eslint-disable-next-line no-undef
      const polygon = new google.maps.Polygon({
        paths: [],
        editable: props.editable || false,
      });
      polygon.setMap(map);

      const pathChanged = () => {
        emit(
          "update:path",
          polygon
            .getPath()
            .getArray()
            .map(p => ({ lat: p.lat(), lng: p.lng() })),
        );
      };

      const setListeners = () => {
        if (polygon.getPath()) {
          polygon.addListener("dblclick", (event: google.maps.PolyMouseEvent) => {
            const points = polygon.getPath().getArray();
            if (event.vertex && points.length > 2) {
              emit(
                "update:path",
                points.filter((_, index) => index != event.vertex).map(p => ({ lat: p.lat(), lng: p.lng() })),
              );
            }
          });
          polygon.getPath().addListener("insert_at", pathChanged);
          polygon.getPath().addListener("remove_at", pathChanged);
          polygon.getPath().addListener("set_at", pathChanged);
        }
      };

      if (pathRef.value && pathRef.value.length > 0) {
        polygon.setPath(pathRef.value);
        setListeners();
      }
      watch(pathRef, () => {
        if (pathRef.value && pathRef.value.length > 0) {
          polygon.setPath(pathRef.value);
          setListeners();
        }
      });

      if (path && path.length) {
        path.forEach(c => (bounds as unknown as google.maps.LatLngBounds).extend((c as unknown) as google.maps.LatLngLiteral));
      }
      (map as unknown as google.maps.Map).fitBounds(bounds as unknown as google.maps.LatLngBounds);

      const mapClicked = (event: google.maps.MapMouseEvent) => {
        if (props.editable) {
          if (!pathRef.value || pathRef.value.length < 2) {
            const updatedPath = [...(pathRef.value || []), { lat: event.latLng?.lat(), lng: event.latLng?.lng() }];
            emit("update:path", updatedPath);
          }
        }
      };
      (map as unknown as google.maps.Map).addListener("click", mapClicked);
    });

    return {
      computedPath: [],
    };
  },
});
