
import {defineComponent, inject, ref, watch} from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import {Equipment, equipment} from "@/api";
import { useRoute } from "vue-router";
import { useRemoteData } from "@/hooks/useRemoteData";
import eventemitter from "eventemitter3";
import {useRemoteList} from "@/hooks/useRemoteList";
import {useExportToCsv} from "@/hooks/useExportToCsv";

export default defineComponent({
  name: "Equipment",
  components: {
    DhButton,
    DhHeader,
    DhTable,
    DhTableColumn,
  },
  setup() {
    const [rows, pagination, loading] = useRemoteList(equipment.list);

    const exportToCsv = useExportToCsv({
      filename: "equipment.csv",
      action: () =>
        rows.value.map(row => ({
          Equipment: row.name,
          "Job Type": row.jobType?.name,
          "Make & Model": [row.make, row.model].filter(Boolean).join(" "),
          Year: row.year,
          "Operation Cost": row.operationCost,
          "Task Priority": "Task Priority",
          "Beacon #": row.beacon?.beaconID,
        })),
    });

    return { rows, loading, exportToCsv };
  },
});
