<template>
  <strong class="inline-block text-gray-500 text-sm uppercase font-normal">{{ label }}</strong>
  <span class=""><slot></slot></span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "EquipmentSummaryItem",
  props: {
    label: String,
  },
});
</script>
