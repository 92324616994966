<template>
  <dh-slide-over-form back="locations" @submit="handleSubmit" :showDelete="false">
    <template #title>Import John Deere Maps</template>
    <div>
      <p class="mb-4 text-sm bg-yellow-100 text-yellow-900 p-2 rounded">The primary .sh file name should match the exact name of the .zip file being uploaded. E.g., if your zip file is named locations.zip, the primary .sh file inside the compressed zip file would be named locations.sh.</p>
      <dh-label id="upload-input" :required="true">File</dh-label>
      <input name="name" type="file" ref="fileInput" required class="border-2 border-solid border-gray-400 rounded-lg p-2 w-full" />
    </div>
  </dh-slide-over-form>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {locations} from "@/api";
import DhSlideOverForm, {ExtendedMouseEvent} from "@/components/DhSlideOverForm.vue";
import DhLabel from "@/components/DhLabel.vue";

export default defineComponent({
  name: "Profile",
  components: {
    DhLabel,
    DhSlideOverForm,
  },
  setup() {
    const fileInput = ref<HTMLInputElement>();

    const handleSubmit = (event: ExtendedMouseEvent) => {
      if (!fileInput.value) {
        throw Error("A valid zip file is required");
      }

      if (!fileInput.value?.files?.length) {
        throw Error("You must upload at least one zip file");
      }

      const body = new FormData();
      body.append("geodata_bundle", fileInput.value?.files[0]);

      event.waitUntil(locations.geofences.import(body));
    };

    return {
      fileInput,
      handleSubmit,
    };
  },
});
</script>
