<template>
  <div class="sr-only">{{ JSON.stringify({ point }) }}</div>
</template>

<script lang="ts">
import {defineComponent, inject, Ref, ref, toRef, toRefs, unref, watch} from "vue";

export default defineComponent({
  name: "DhMapPin",
  props: {
    point: Object,
    lat: Number,
    lng: Number,
    label: String,
  },
  setup(props) {
    const { point: pointRef } = toRefs(props);

    const mapRef = ref() as Ref<google.maps.Map | null>;
    const boundsRef = ref() as Ref<google.maps.LatLngBounds | null>;
    (inject("map") as Promise<{ map: google.maps.Map; bounds: google.maps.LatLngBounds }>).then(({ map, bounds }) => {
      mapRef.value = map;
      boundsRef.value = bounds;
    });

    const marker = ref();
    watch([mapRef, pointRef], ([map, point]) => {
      if (map && pointRef.value) {
        if (marker.value) {
          marker.value.setMap(null);
        }
        // eslint-disable-next-line no-undef
        marker.value = new google.maps.Marker({
          position: pointRef.value as google.maps.LatLngLiteral,
          label: props.label,
          map: map as google.maps.Map,
        });

        if (boundsRef.value) {
          boundsRef.value.extend(pointRef.value as google.maps.LatLngLiteral);
          (map as google.maps.Map).fitBounds(boundsRef.value);
        }
      }
    });

    return {
    };
  },
});
</script>
