
import { defineComponent, h } from "vue";

export default defineComponent({
  name: "DhVNodes",
  functional: true,
  props: {
    vnodes: [Object, Array],
  },
  setup(props) {
    return () => props.vnodes;
  },
});
