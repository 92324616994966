
import { defineComponent, ref } from "vue";
import DhHeader from "@/components/DhHeader.vue";
import DhTable from "@/components/DhTable.vue";
import DhTableColumn from "@/components/DhTableColumn.vue";
import DhButton from "@/components/DhButton.vue";
import DhDateTime from "@/components/DhDateTime.vue";
import DhDuration from "@/components/DhDuration.vue";
import DhPagination from "@/components/DhPagination.vue";
import { useRemoteList } from "@/hooks/useRemoteList";
import { tickets, users } from "@/api";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import { useExportToCsv } from "@/hooks/useExportToCsv";
import SvgCheck from "@/assets/check.svg";
import {parse} from "date-fns";

export default defineComponent({
  name: "TimeCards",
  components: {
    DhHeader,
    DhTable,
    DhTableColumn,
    DhButton,
    DhDateTime,
    DhDuration,
    DhPagination,
    SvgCheck,
  },
  setup() {
    const filterUserId = ref<string | undefined>();
    const filterDate = ref<string | undefined>();
    const [allUsers] = useRemoteList(users.list);

    const [rows, pagination, loading] = useRemoteList(
      page => {
        let postDate: string | undefined = undefined;
        if (filterDate.value) {
          const start = `${filterDate.value}T00:00:00.000Z`;
          const end = `${filterDate.value}T23:59:59.999Z`;
          postDate = `${start}|${end}`;
        }
        return tickets.batch.list(page, {
          userID: filterUserId.value,
          postDate,
        });
      },
      {
        idField: "batchID",
        watch: [filterUserId, filterDate],
      },
    );

    const exportToCsv = useExportToCsv({
      filename: "time-cards.csv",
      action: () =>
        rows.value.map(row => ({
          Name: row.createdBy.username,
          Date: row.timeClockedIn,
          "Total Time": row.totalTime,
          "Ticket Count": row.ticketList.length,
          "Lock Status": row.postDate && row.postLocation ? "🔒" : "",
        })),
    });

    return {
      rows,
      loading,
      exportToCsv,
      pagination,
      formatDuration,
      intervalToDuration,
      filterUserId,
      filterDate,
      allUsers,
    };
  },
});
